import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box, Button, Flex, Heading } from "rimble-ui";
import CreditVehicleAction from "../components/CreditVehicleAction";
import CreditVehicleInfoCard from "../components/CreditVehicleInfoCard";
import { sleep } from "../utils";

const CreditVehicle = ({ creditVehicleProvider, accounts }) => {
  const history = useHistory();
  const [token, setToken] = useState("");
  const [type, setType] = useState("");
  const [tokenAddress, setTokenAddress] = useState("");
  const [interestRate, setInterestRate] = useState("-");
  const [ratePerBlock, setRatePerBlock] = useState(0);
  const [originationFee, setOriginationFee] = useState("-");
  const [borrowed, setBorrowed] = useState("-");
  const [creditLimit, setCreditLimit] = useState("-");
  const [remainingCreditLimit, setRemainingCreditLimit] = useState("-");
  const [loanSizeMax, setLoanSizeMax] = useState("-");
  const [loanSizeMin, setLoanSizeMin] = useState("-");
  const [poolBalance, setPoolBalance] = useState("-");
  const [overdueDate, setOverdueDate] = useState("-");
  const [isOverdue, setIsOverdue] = useState("-");
  const [interest, setInterest] = useState("-");

  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [balance, setBalance] = useState("-");
  const { address: creditVehicleAddress } = useParams();
  const server = creditVehicleProvider;
  useEffect(() => {
    loadData();
    loadUserData();
  }, [
    creditVehicleProvider,
    accounts,
    creditVehicleAddress,
    loadData,
    loadUserData
  ]);

  const getBalance = useCallback(async (creditVehicleAddress, address) => {
    const server = creditVehicleProvider;
    if (server && creditVehicleAddress && address) {
      const balance = await server.getBalance(creditVehicleAddress, address);
      setBalance(balance ? balance.toFixed(4) : 0.0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = useCallback(async () => {
    if (server) {
      const data = await server.getCreditVehicleData(creditVehicleAddress);
      setToken(data.token);
      setTokenAddress(data.tokenAddress);
      setRatePerBlock(data.ratePerBlock);
      setInterestRate(
        data.interestRate === undefined
          ? "-"
          : (data.interestRate * 100).toFixed(2)
      );
      setOriginationFee(
        data.originationFee === undefined
          ? "-"
          : (data.originationFee * 100).toFixed(2)
      );
      setLoanSizeMax(
        data.loanSizeMax === undefined ? "-" : data.loanSizeMax.toFixed(4)
      );
      setLoanSizeMin(
        data.loanSizeMin === undefined ? "-" : data.loanSizeMin.toFixed(4)
      );
      setPoolBalance(
        data.poolBalance === undefined ? "-" : data.poolBalance.toFixed(4)
      );
    }

    while (true) {
      if (accounts && accounts[0]) {
        const address = accounts[0];
        getBalance(creditVehicleAddress, address);
        setAddress(address);
        break;
      } else {
        await sleep(500);
      }
    }
  });

  const loadUserData = useCallback(async () => {
    while (true) {
      if (accounts && accounts[0]) {
        const address = accounts[0];
        if (server) {
          const userData = await server.getUserData(
            creditVehicleAddress,
            address
          );
          setBorrowed(
            userData.borrowed === undefined ? "–" : userData.borrowed.toFixed(4)
          );
          setCreditLimit(
            userData.creditLimit === undefined
              ? "–"
              : userData.creditLimit.toFixed(4)
          );
          setRemainingCreditLimit(
            userData.remainingCreditLimit === undefined
              ? "–"
              : userData.remainingCreditLimit.toFixed(4)
          );
          setOverdueDate(
            userData.overdueDate === undefined ? "–" : userData.overdueDate
          );
          setIsOverdue(
            userData.isOverdue === undefined
              ? "–"
              : userData.isOverdue
              ? "Yes"
              : "No"
          );
          //interest is min repay amount, to prevent insufficient amounts, round up
          setInterest(
            userData.interest === undefined
              ? "–"
              : Math.ceil(userData.interest * 10000) / 10000
          );
        }
        break;
      } else {
        await sleep(500);
      }
    }
  });

  const goBack = () =>
    history.length <= 2 ? history.push("/") : history.goBack();

  const borrow = async () => {
    const server = creditVehicleProvider;
    if (!creditVehicleAddress) {
      window.toastProvider.addMessage("CreditVehicle Address Not Found");
    }

    if (!address) {
      window.toastProvider.addMessage("User Address Not Found");
    }

    if (parseFloat(amount) > 0) {
      await server.borrow(creditVehicleAddress, address, amount);
      loadData();
      loadUserData();
    }
  };

  const repay = async () => {
    const server = creditVehicleProvider;
    if (!creditVehicleAddress) {
      window.toastProvider.addMessage("CreditVehicle Address Not Found");
    }

    if (!address) {
      window.toastProvider.addMessage("User Address Not Found");
    }

    if (parseFloat(amount) > 0) {
      await server.repay(creditVehicleAddress, address, amount);
      loadData();
      loadUserData();
    }
  };

  const max = async () => {
    if (parseFloat(borrowed)) {
      setAmount(
        Math.ceil(
          (parseFloat(borrowed) + parseFloat(ratePerBlock * 2)) * 10000
        ) / 10000
      );
    }
  };

  const min = async () => {
    if (parseFloat(interest)) {
      setAmount(
        Math.ceil(
          (parseFloat(interest) + parseFloat(ratePerBlock * 2)) * 10000
        ) / 10000
      );
    }
  };

  const confirm = async () => {
    if (type === "borrow") {
      await borrow();
    } else if (type === "repay") {
      await repay();
    }
  };

  const onChangeValue = v => {
    setAmount(v.target.value);
  };

  const maxPepay = parseFloat(borrowed)
    ? Math.ceil((parseFloat(borrowed) + parseFloat(ratePerBlock * 2)) * 10000) /
      10000
    : 0;
  const minRepay = parseFloat(interest)
    ? Math.ceil((parseFloat(interest) + parseFloat(ratePerBlock * 2)) * 10000) /
      10000
    : 0;

  const creditVehicleInfoCardData = {
    token,
    tokenAddress,
    interestRate,
    originationFee,
    creditLimit,
    remainingCreditLimit,
    loanSizeMax,
    loanSizeMin,
    poolBalance,
    overdueDate,
    isOverdue,
    borrowed: maxPepay,
    interest: minRepay
  };

  return (
    <>
      <Flex alignItems="center" mb={4}>
        <Button icon="KeyboardArrowLeft" size="small" mr={4} onClick={goBack}>
          Back
        </Button>
        <Heading.h1>Credit Vehicle</Heading.h1>
      </Flex>

      <Flex mx={-3} mb={40} flexDirection={["column", null, "row"]}>
        <Box px={3} flex={1} mb={[48, 0]} color="black">
          <CreditVehicleInfoCard data={creditVehicleInfoCardData} />
        </Box>

        <Box px={3} flex={1} color="black">
          <CreditVehicleAction
            borrowed={maxPepay}
            interest={minRepay}
            balance={balance}
            borrow={borrow}
            repay={repay}
            max={max}
            min={min}
            amount={amount}
            onChangeValue={onChangeValue}
          />
        </Box>
      </Flex>
    </>
  );
};

export default CreditVehicle;
