import React from "react";
import { Box, Button, Card, Flex, Heading, Modal } from "rimble-ui";

export default class MemberRemove extends React.Component {
  async removeMember() {
    const { provider, data } = this.props;
    if (provider && data && data.member) {
      await provider.removeMember(data.member);
    }
  }

  render() {
    const { isOpen, closeModal } = this.props;
    return (
      <React.Fragment>
        <Modal isOpen={isOpen}>
          <Card width={"660px"} p={0}>
            <Button.Text
              icononly
              icon={"Close"}
              color={"moon-gray"}
              position={"absolute"}
              top={0}
              right={0}
              mt={0}
              mr={0}
              onClick={closeModal}
            />
            <Box py={40} px={32}>
              <Flex>
                <Box p={3} width={1 / 1}>
                  <Heading.h3>
                    {this.props.data ? this.props.data.member : ""}
                  </Heading.h3>
                </Box>
              </Flex>
            </Box>
            <Flex
              px={4}
              py={3}
              borderTop={1}
              borderColor={"#E8E8E8"}
              justifyContent={"flex-end"}
            >
              <Button.Outline onClick={closeModal}>Cancel</Button.Outline>
              <Button ml={3} onClick={this.removeMember.bind(this)}>
                Remove
              </Button>
            </Flex>
          </Card>
        </Modal>
      </React.Fragment>
    );
  }
}
