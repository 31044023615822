import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Loader,
  Table,
  Text
} from "rimble-ui";
import Token from "../Token";

const CreditVehicleTable = ({ creditVehicleProvider, accounts }) => {
  const [creditVehicles, setCreditVehicles] = useState([]);
  const { push } = useHistory();

  useEffect(() => {
    const loadCreditVehicles = async () => {
      const server = creditVehicleProvider;

      console.log("creditVehicleProvider active: ", server.active);

      const creditVehicles = await server.getCreditVehicles();

      console.log("creditVehicles: ", creditVehicles);
      const arr = [];

      const address = accounts ? accounts[0] : "";
      console.log("address: ", address);

      const promises = creditVehicles.map(async (v, i) => {
        const data = await server.getCreditVehicleData(v);
        const userData = await server.getUserData(v, address);
        arr.push(Object.assign({ address: v }, data, userData));
      });
      await Promise.all(promises);

      arr.sort(function(a, b) {
        return a.token && b.token
          ? a.token.toString().localeCompare(b.token)
          : 1;
      });
      console.log(arr);

      setCreditVehicles(arr);
    };

    loadCreditVehicles();
  }, [accounts, creditVehicleProvider]);

  const gotoDetail = address => push(`/creditVehicle/${address}`);

  return (
    <>
      <Flex alignItems="center" mb={4}>
        <Heading.h1>Credit Vehicles</Heading.h1>
      </Flex>

      {creditVehicles.length > 0 &&
      creditVehicles.filter(({ borrowed }) => borrowed > 0).length > 0 ? (
        <Card p={0} my={32}>
          <Box px={32} py={24}>
            <Heading.h2>Borrowed</Heading.h2>
          </Box>

          <Table bg="white" border={0} borderColor="#eee">
            <thead>
              <tr>
                <th width="16%">Credit Vehicle</th>
                <th width="16%">Available Credit</th>
                <th width="16%">Balance Owed</th>
                <th width="16%">Payment Due</th>
                <th width="16%">Borrow APR</th>
                <th width="20%">&nbsp;</th>
              </tr>
            </thead>

            <tbody>
              {creditVehicles.length > 0 ? (
                creditVehicles.map(
                  (v, i) =>
                    v.borrowed > 0 && (
                      <tr key={i}>
                        <td width="20%">
                          <Token token={v.token} />
                        </td>
                        <td>{v.remainingCreditLimit.toFixed(4)}</td>
                        <td>{v.borrowed.toFixed(4)}</td>
                        <td style={{ color: v.isOverdue ? "red" : "inherit" }}>
                          {v.overdueDate}
                        </td>
                        <td>{(v.interestRate * 100).toFixed(2)}%</td>
                        <td>
                          <Flex justifyContent="flex-end">
                            <Button
                              size="small"
                              onClick={() => gotoDetail(v.address)}
                            >
                              Manage
                            </Button>
                          </Flex>
                        </td>
                      </tr>
                    )
                )
              ) : (
                <tr>
                  <td colSpan="4">
                    <Text textAlign="center">
                      <Loader size="40px" style={{ display: "inline-block" }} />
                    </Text>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card>
      ) : null}

      <Card p={0} my={32}>
        <Box px={32} py={24}>
          <Heading.h2>Available</Heading.h2>
        </Box>

        <Table bg="white" border={0} borderColor="#eee">
          <thead>
            <tr>
              <th width="20%">Credit vehicle</th>
              <th width="20%">Credit limit</th>
              <th width="20%">Borrow APR</th>
              <th width="20%">Origination fee</th>
              <th width="20%">&nbsp;</th>
            </tr>
          </thead>

          <tbody>
            {creditVehicles.length > 0 ? (
              creditVehicles.map(
                (v, i) =>
                  (v.borrowed <= 0 || v.borrowed === undefined) && (
                    <tr key={i}>
                      <td width="20%">
                        <Token token={v.token} />
                      </td>
                      <td width="20%">
                        {v.remainingCreditLimit
                          ? v.remainingCreditLimit.toFixed(4)
                          : "0.0000"}
                      </td>
                      <td width="20%">{(v.interestRate * 100).toFixed(2)}%</td>
                      <td width="20%">
                        {(v.originationFee * 100).toFixed(2)}%
                      </td>
                      <td width="20%">
                        <Flex justifyContent="flex-end">
                          <Button
                            size="small"
                            onClick={() => gotoDetail(v.address)}
                          >
                            Borrow
                          </Button>
                        </Flex>
                      </td>
                    </tr>
                  )
              )
            ) : (
              <tr>
                <td colSpan="5">
                  <Text textAlign="center">
                    <Loader size="40px" style={{ display: "inline-block" }} />
                  </Text>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

export default CreditVehicleTable;
