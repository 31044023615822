import React from "react";
import {
  Box,
  Button,
  Card,
  Field,
  Flex,
  Input,
  Modal,
  Select
} from "rimble-ui";

export default class AdminAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      address: "",
      isAdmin: false,
      select: "memberManager"
    };
  }

  async componentDidMount() {
    const isAdmin = await this.isAdmin(this.state.select);
    this.setState({
      isAdmin
    });
  }

  async onChangeValue(key, v) {
    const value = v.target.value;
    if (key === "select") {
      const isAdmin = await this.isAdmin(value);
      this.setState({
        isAdmin
      });
    };

    this.setState({
      [key]: value
    });
  }

  async addAdmin() {
    const { provider } = this.props;
    if (provider) {
      if (this.state.select === "memberManager") {
        await provider.addMemberAdmin(this.state.address);
      } else {
        await provider.addAdmin(this.state.select, this.state.address);
      }
    }
  }

  async isAdmin(select) {
    const { provider } = this.props;
    if (provider) {
      if (select === "memberManager") {
        return await provider.isMemberAdmin();
      } else {
        return await provider.isAdmin(select);
      }
    } else {
      return false;
    }
  }

  render() {
    const { isOpen, closeModal, options } = this.props;
    return (
      <React.Fragment>
        <Modal isOpen={isOpen}>
          <Card width={"660px"} p={0}>
            <Button.Text
              icononly
              icon={"Close"}
              color={"moon-gray"}
              position={"absolute"}
              top={0}
              right={0}
              mt={0}
              mr={0}
              onClick={closeModal}
            />
            <Box py={40} px={32}>
              <Flex>
                <Box p={3} width={1 / 1}>
                  <Field label="Choose contract" width={1 / 1}>
                    <Select
                      required={true}
                      options={options}
                      defaultValue={this.state.select}
                      onChange={this.onChangeValue.bind(this, "select")}
                      width={1 / 1}
                    />
                  </Field>
                </Box>
              </Flex>
              <Flex>
                <Box p={3} width={1 / 1}>
                  <Input
                    type="text"
                    size="small"
                    placeholder="Admin address"
                    width="100%"
                    onChange={this.onChangeValue.bind(this, "address")}
                  />
                </Box>
              </Flex>
            </Box>
            <Flex
              px={4}
              py={3}
              borderTop={1}
              borderColor={"#E8E8E8"}
              justifyContent={"flex-end"}
            >
              <Button.Outline onClick={closeModal}>Cancel</Button.Outline>
              <Button
                ml={3}
                onClick={this.addAdmin.bind(this)}
                disabled={!this.state.isAdmin}
              >
                Add
              </Button>
            </Flex>
          </Card>
        </Modal>
      </React.Fragment>
    );
  }
}
