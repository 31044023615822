import React from "react";
import { Box, Button, Card, Flex, Heading, Input, Modal } from "rimble-ui";

export default class CreditVehicleDepositAndWithdraw extends React.Component {
  onChangeValue(v) {
    this.setState({
      amount: v.target.value
    });
  }

  async confirm() {
    const { provider, type } = this.props;
    if (provider) {
      if (type === "deposit") {
        await provider.deposit(this.props.data.address, this.state.amount);
      } else if (type === "withdraw") {
        await provider.withdraw(this.props.data.address, this.state.amount);
      } else {
        throw new Error("Type not found");
      }
    }
  }

  render() {
    const { isOpen, closeModal, data, type } = this.props;

    return (
      <React.Fragment>
        <Modal isOpen={isOpen}>
          <Card width={"660px"} p={0}>
            <Button.Text
              icononly
              icon={"Close"}
              color={"moon-gray"}
              position={"absolute"}
              top={0}
              right={0}
              mt={0}
              mr={0}
              onClick={closeModal}
            />
            <Box py={40} px={32}>
              <Flex>
                <Box p={3} width={1 / 2}>
                  <Heading.h3>
                    Contract Balance:{" "}
                    {data.balance
                      ? data.balance.contractBalance.toFixed(4)
                      : 0.0}
                  </Heading.h3>
                </Box>
                <Box p={3} width={1 / 2}>
                  <Heading.h3>
                    Wallet Balance:{" "}
                    {data.balance ? data.balance.walletBalance.toFixed(4) : 0.0}
                  </Heading.h3>
                </Box>
              </Flex>
              <Flex>
                <Box p={3} width={1}>
                  <Heading.h3>
                    {type === "deposit" ? "Deposit" : "Withdraw"} amount
                  </Heading.h3>
                  <Input
                    type="number"
                    size="small"
                    placeholder=""
                    width="100%"
                    onChange={this.onChangeValue.bind(this)}
                  />
                </Box>
              </Flex>
            </Box>
            <Flex
              px={4}
              py={3}
              borderTop={1}
              borderColor={"#E8E8E8"}
              justifyContent={"flex-end"}
            >
              <Button.Outline onClick={closeModal}>Cancel</Button.Outline>
              <Button ml={3} onClick={this.confirm.bind(this)}>
                Confirm
              </Button>
            </Flex>
          </Card>
        </Modal>
      </React.Fragment>
    );
  }
}
