import React, { Component } from "react";
import {
  Button,
  Card,
  Flex,
  Heading,
  Icon,
  Loader,
  Table,
  Text
} from "rimble-ui";
import AdminAddModal from "../Modals/adminAdd.js";
import AdminEditModal from "../Modals/adminEdit.js";
import Address from "../Address/index.js";

export default class Admins extends Component {
  constructor(props) {
    super(props);
    this.state = {
      superAdmins: [],
      admins: [],
      addModalIsOpen: false,
      editModalIsOpen: false,
      selectedAdmin: {},
      adminsObj: {},
      menus: [],
      userAdmins: [],
      loading: false
    };
  }

  async componentDidMount() {
    const { creditVehicleProvider: server, creditVehicles } = this.props;
    if (server && server.active) {
      this.setState({
        loading: true
      });
      const memberAdmins = await server.getMemberAdmins();
      const isSuperAdmin = await server.isMemberSuperAdmin();
      const isAdmin = await server.isMemberAdmin();

      let adminsObj = {},
        menus = [{ value: "memberManager", label: "memberManager" }],
        superAdmins = [],
        admins = [];

      if (isSuperAdmin)
        superAdmins.push({ value: "memberManager", label: "memberManager" });

      if (isAdmin)
        admins.push({ value: "memberManager", label: "memberManager" });

      memberAdmins.forEach(v => {
        if (v !== "0x0000000000000000000000000000000000000000") {
          if (adminsObj[v]) {
            adminsObj[v].push("memberManager");
          } else {
            adminsObj[v] = ["memberManager"];
          }
        }
      });

      const promises = creditVehicles.map(async address => {
        const allAdmins = await server.getAdmins(address);
        const isSuperAdmin = await server.isSuperAdmin(address);
        const isAdmin = await server.isAdmin(address);
        const tokenName = await server.getCreditVehicleToken(address);

        if (isSuperAdmin)
          superAdmins.push({ value: address, label: tokenName });

        if (isAdmin) admins.push({ value: address, label: tokenName });

        menus.push({
          value: address,
          label: tokenName
        });

        allAdmins.forEach(v => {
          if (v !== "0x0000000000000000000000000000000000000000") {
            if (adminsObj[v]) {
              adminsObj[v].push(address);
            } else {
              adminsObj[v] = [address];
            }
          }
        });
      });
      await Promise.all(promises);

      this.setState({
        superAdmins,
        admins,
        adminsObj,
        menus,
        loading: false
      });
    }
  }

  closeModal(e) {
    e.preventDefault();
    this.setState((state, props) => ({
      addModalIsOpen: false
    }));
  }

  openModal(e) {
    e.preventDefault();
    this.setState((state, props) => ({
      addModalIsOpen: true
    }));
  }

  closeEditModal(e) {
    e.preventDefault();
    this.setState((state, props) => ({
      editModalIsOpen: false
    }));
  }

  async openEditModal(address, userAdmins, e) {
    e.preventDefault();
    const { superAdmins, admins } = this.state;
    this.setState((state, props) => ({
      editModalIsOpen: true,
      selectedAdmin: {
        address,
        admins,
        superAdmins,
        userAdmins
      }
    }));
  }

  render() {
    const {
      state: { menus, adminsObj }
    } = this;
    const that = this;
    const menusHtml = menus.map((v, i) => {
      return <th key={i}>{v.label}</th>;
    });

    let bodyHtml = [];
    for (const address in adminsObj) {
      let userAdmins = [];
      const html = menus.map((v, i) => {
        let isAdmin = false;
        if (adminsObj[address].indexOf(v["value"]) !== -1) {
          isAdmin = true;
          userAdmins.push(v);
        }
        return (
          <td key={i}>{isAdmin ? <Icon name="Check" size="40" /> : ""}</td>
        );
      });

      bodyHtml.push(
        <tr key={address}>
          <td>
            <Address address={address} />
          </td>
          {html}
          <td>
            <Button.Outline
              size="small"
              onClick={that.openEditModal.bind(that, address, userAdmins)}
            >
              Edit
            </Button.Outline>
          </td>
        </tr>
      );
    }

    return (
      <>
        <AdminAddModal
          isOpen={this.state.addModalIsOpen}
          closeModal={this.closeModal.bind(this)}
          provider={this.props.creditVehicleProvider}
          options={this.state.menus}
        />

        <AdminEditModal
          isOpen={this.state.editModalIsOpen}
          closeModal={this.closeEditModal.bind(this)}
          provider={this.props.creditVehicleProvider}
          data={this.state.selectedAdmin}
          options={this.state.menus}
        />

        <Card p={0} mb={40}>
          <Flex py={24} px={32} justifyContent="space-between">
            <Heading.h2>Admins</Heading.h2>
            <Button
              size="small"
              style={{ float: "right" }}
              onClick={this.openModal.bind(this)}
            >
              Add
            </Button>
          </Flex>
          <Table bg="white" borderColor="#eee" border="none">
            <thead>
              <tr>
                <th>Address</th>
                {menusHtml}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.state.loading ? (
                <tr>
                  <td colSpan="2">
                    <Text textAlign="center">
                      <Loader size="40px" style={{ display: "inline-block" }} />
                    </Text>
                  </td>
                </tr>
              ) : (
                <>{bodyHtml}</>
              )}
            </tbody>
          </Table>
        </Card>
      </>
    );
  }
}
