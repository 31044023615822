import { useWeb3 } from "@openzeppelin/network/react";
import ConnectionBanner from "@rimble/connection-banner";
import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  BaseStyles,
  Box,
  Card,
  Flash,
  Flex,
  Heading,
  Icon,
  MetaMaskButton,
  Text,
  ThemeProvider,
  ToastMessage
} from "rimble-ui";
import Header from "./components/Header/index.js";
import { infuraProjectId } from "./config.js";
import CreditVehicle from "./layout/creditVehicle.js";
import Faucet from "./layout/faucet.js";
import Home from "./layout/home.js";
import Manage from "./layout/manage.js";
import theme from "./lib/theme/index.js";
import CreditVehicleProvider from "./services/CreditVehicleProvider";

let providers = {};

const App = () => {
  const web3Context = useWeb3(`wss://kovan.infura.io/ws/v3/${infuraProjectId}`);

  const [creditVehicleProvider, setCreditVehicleProvider] = useState(undefined);
  const [isMember, setIsMember] = useState(undefined);

  const { networkId, accounts, providerName, connected } = web3Context;

  const checkIsMember = useCallback(async (creditVehicleProvider, address) => {
    const isMember = await creditVehicleProvider.isMember(address);
    setIsMember(isMember);
  });

  useEffect(() => {
    if (accounts && accounts.length && !creditVehicleProvider && !!networkId) {
      setCreditVehicleProvider(new CreditVehicleProvider(web3Context));
      const newCreditVehicleProvider = new CreditVehicleProvider(web3Context);
      checkIsMember(newCreditVehicleProvider, accounts[0]);
      providers[networkId] = newCreditVehicleProvider;
      setCreditVehicleProvider(newCreditVehicleProvider);
    }
  }, [creditVehicleProvider, accounts, networkId, web3Context, checkIsMember]);

  web3Context.on("NetworkIdChanged", (networkId, networkName) => {
    if (accounts && accounts.length) {
      if (!providers[networkId]) {
        const newCreditVehicleProvider = new CreditVehicleProvider(web3Context);
        providers[networkId] = newCreditVehicleProvider;
        setCreditVehicleProvider(newCreditVehicleProvider);
      } else {
        setCreditVehicleProvider(providers[networkId]);
      }
    }
  });

  web3Context.on("AccountsChanged", accounts => {
    if (creditVehicleProvider && accounts && accounts.length) {
      checkIsMember(creditVehicleProvider, accounts[0]);
      setCreditVehicleProvider(providers[networkId]);
    }
  });

  const requestAuth = async web3Context => {
    try {
      await web3Context.requestAuth();
    } catch (e) {
      console.error(e);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const requestAccess = useCallback(() => requestAuth(web3Context), []);

  return (
    <ThemeProvider theme={theme}>
      <BaseStyles>
        <ToastMessage.Provider ref={node => (window.toastProvider = node)} />
        <BrowserRouter>
          <Header web3Context={web3Context} networkId={networkId} />

          <Box maxWidth={1280 + 32} mx="auto" px={32}>
            {isMember !== undefined && !isMember && (
              <Flash my={3} variant="danger">
                <Flex alignItems="center">
                  <Box pr={3}>
                    <Icon name="Warning" size="44" />
                  </Box>
                  <Flex flexDirection="column">
                    <Text fontWeight="bold" color="inherit">
                      Not member address
                    </Text>
                    Your current wallet address is not a member address
                  </Flex>
                </Flex>
              </Flash>
            )}

            {!window.ethereum && <ConnectionBanner onWeb3Fallback={true} />}

            {accounts && accounts.length ? null : !!connected &&
              !!networkId &&
              providerName !== "infura" ? (
              <Card maxWidth={420} mx="auto">
                <Heading.h3 textAlign="center">
                  Connect wallet to get started
                </Heading.h3>

                <Text.p mb={24} mt={16} textAlign="center">
                  You'll need to connect your wallet with MetaMask before you
                  can use this app.
                </Text.p>

                <MetaMaskButton.Outline width={1} onClick={requestAccess}>
                  Connect with MetaMask
                </MetaMaskButton.Outline>
              </Card>
            ) : null}

            {accounts &&
              accounts.length > 0 &&
              creditVehicleProvider &&
              creditVehicleProvider.active && (
                <Switch>
                  <Route path="/" exact>
                    <Home
                      creditVehicleProvider={creditVehicleProvider}
                      accounts={accounts}
                    />
                  </Route>

                  <Route path="/creditVehicle/:address">
                    <CreditVehicle
                      creditVehicleProvider={creditVehicleProvider}
                      accounts={accounts}
                    />
                  </Route>

                  <Route path="/manage">
                    <Manage
                      creditVehicleProvider={creditVehicleProvider}
                      accounts={accounts}
                    />
                  </Route>

                  <Route path="/faucet">
                    <Faucet creditVehicleProvider={creditVehicleProvider} />
                  </Route>
                </Switch>
              )}
          </Box>
        </BrowserRouter>
      </BaseStyles>
    </ThemeProvider>
  );
};

export default App;
