import React from "react";
import { Box, Card, Flex, Loader, Text } from "rimble-ui";

import Token from "../Token";

const InfoRow = ({ label, value, isLastItem = false }) => (
  <Box
    pt="8px"
    pb="7px"
    borderBottom={!isLastItem ? "1px solid #eee" : undefined}
  >
    <Flex justifyContent="space-between">
      <Text fontWeight={"500"}>{label}:</Text>
      <Text>{value}</Text>
    </Flex>
  </Box>
);

const CreditVehicleInfoCard = ({ data }) => {
  const {
    token,
    tokenAddress,
    interestRate,
    originationFee,
    borrowed,
    creditLimit,
    remainingCreditLimit,
    loanSizeMax,
    loanSizeMin,
    poolBalance,
    overdueDate,
    isOverdue,
    interest
  } = data;

  return (
    <Card p={0}>
      <Flex py={24} px={32} justifyContent="center">
        {token ? <Token token={token} large /> : <Loader size="40px" />}
      </Flex>

      <Box pt={32 - 1} px={32} pb={32} borderTop="1px solid #eee">
        <InfoRow label="Borrow APR" value={`${interestRate}%`} />
        <InfoRow label="Origination Fee" value={`${originationFee}%`} />
        <InfoRow label="Pool Balance" value={poolBalance} />
        <InfoRow label="Loan Max" value={loanSizeMax} />
        <InfoRow label="Loan Min" value={loanSizeMin} />
        <InfoRow label="Credit Limit" value={creditLimit} />
        <InfoRow label="Remaining Limit" value={remainingCreditLimit} />
        <InfoRow label="Balance Owed" value={borrowed} />
        <InfoRow label="Min Repayment Amount" value={interest} />
        <InfoRow label="Overdue Date" value={overdueDate} />
        <InfoRow label="Is Overdue" value={isOverdue} isLastItem />
      </Box>
    </Card>
  );
};

export default CreditVehicleInfoCard;
