import React, { Component } from "react";
import { Button, Card, Flex, Heading, Loader, Table, Text } from "rimble-ui";
import CreditVehicleAddModal from "../Modals/creditVehicleAdd.js";
import CreditVehicleDepositAndWithdrawModal from "../Modals/creditVehicleDepositAndWithdraw.js";
import CreditVehicleEditModal from "../Modals/creditVehicleEdit.js";
import Token from "../Token/index.js";

export default class Markets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creditVehicles: [],
      addModalIsOpen: false,
      editModalIsOpen: false,
      depositAndWithdrawModalIsOpen: false,
      selectedVehicle: {},
      modalType: "",
      loading: false
    };
  }

  async componentDidMount() {
    this.setState({
      loading: true
    });
    const server = this.props.creditVehicleProvider;
    if (server && server.active) {
      let creditVehicles = this.props.creditVehicles;
      const arr = [];
      const promises = creditVehicles.map(async (v, i) => {
        const data = await server.getCreditVehicleData(v);
        arr.push(Object.assign({ address: v }, data));
      });
      await Promise.all(promises);

      arr.sort(function(a, b) {
        return a.token && b.token
          ? a.token.toString().localeCompare(b.token)
          : 1;
      });
      console.log(arr);

      this.setState({
        creditVehicles: arr,
        loading: false
      });
    }
  }

  closeModal(e) {
    e.preventDefault();
    this.setState((state, props) => ({
      addModalIsOpen: false
    }));
  }

  openModal(e) {
    e.preventDefault();
    const provider = this.props.creditVehicleProvider;
    if (!provider) {
      console.log("Please wait provider loading finshed");
    } else {
      this.setState((state, props) => ({
        addModalIsOpen: true
      }));
    }
  }

  async openDepositAndWithdrawModal(address, modalType, e) {
    e.preventDefault();
    const provider = this.props.creditVehicleProvider;
    const balance = await provider.balanceOf(address);
    this.setState((state, props) => ({
      depositAndWithdrawModalIsOpen: true,
      modalType,
      selectedVehicle: {
        address,
        balance
      }
    }));
  }

  closeDepositAndWithdrawModal(e) {
    e.preventDefault();
    this.setState((state, props) => ({
      depositAndWithdrawModalIsOpen: false
    }));
  }

  closeEditModal(e) {
    e.preventDefault();
    this.setState((state, props) => ({
      editModalIsOpen: false
    }));
  }

  async openEditModal(address, e) {
    e.preventDefault();
    const provider = this.props.creditVehicleProvider;
    const data = await provider.getCreditVehicleData(address);
    this.setState((state, props) => ({
      editModalIsOpen: true,
      selectedVehicle: {
        address,
        maxLateDuration: data.maxLateDuration,
        epochDuration: data.epochDuration,
        decimals: data.decimals,
        rate: data.interestRate ? (data.interestRate * 100).toFixed(2) : "-",
        fee: data.originationFee ? (data.originationFee * 100).toFixed(2) : "-",
        loanSizeMax: data.loanSizeMax ? data.loanSizeMax.toFixed(4) : "-",
        loanSizeMin: data.loanSizeMin ? data.loanSizeMin.toFixed(4) : "-"
      }
    }));
  }

  render() {
    const that = this;
    const availableHtml = that.state.creditVehicles.map(function(v, i) {
      return (
        <tr key={i}>
          <td>
            <Token token={v.token} />
          </td>
          <td>{v.totalBollowed ? v.totalBollowed.toFixed(4) : "-"}</td>
          <td>{v.poolBalance ? v.poolBalance.toFixed(4) : "-"}</td>
          <td>{v.interestRate ? (v.interestRate * 100).toFixed(2) : "-"} %</td>
          <td>
            <Button.Outline
              size="small"
              onClick={that.openDepositAndWithdrawModal.bind(
                that,
                v.address,
                "deposit"
              )}
            >
              Deposit
            </Button.Outline>
            <Button.Outline
              size="small"
              onClick={that.openDepositAndWithdrawModal.bind(
                that,
                v.address,
                "withdraw"
              )}
            >
              Withdraw
            </Button.Outline>
            <Button.Outline
              size="small"
              onClick={that.openEditModal.bind(that, v.address)}
            >
              Edit
            </Button.Outline>
          </td>
        </tr>
      );
    });

    return (
      <>
        <CreditVehicleAddModal
          isOpen={this.state.addModalIsOpen}
          closeModal={this.closeModal.bind(this)}
          provider={this.props.creditVehicleProvider}
        />

        <CreditVehicleEditModal
          isOpen={this.state.editModalIsOpen}
          closeModal={this.closeEditModal.bind(this)}
          provider={this.props.creditVehicleProvider}
          data={this.state.selectedVehicle}
        />

        <CreditVehicleDepositAndWithdrawModal
          isOpen={this.state.depositAndWithdrawModalIsOpen}
          closeModal={this.closeDepositAndWithdrawModal.bind(this)}
          provider={this.props.creditVehicleProvider}
          data={this.state.selectedVehicle}
          type={this.state.modalType}
        />

        <Card p={0} mb={40}>
          <Flex py={24} px={32} justifyContent="space-between">
            <Heading.h2>Available Markets</Heading.h2>

            <Button
              size="small"
              style={{ float: "right" }}
              onClick={this.openModal.bind(this)}
            >
              Add
            </Button>
          </Flex>

          <Table bg="white" borderColor="#eee" border="none">
            <thead>
              <tr>
                <th>Asset</th>
                <th>Total Owed</th>
                <th>Total Supply</th>
                <th>Borrowing APR</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.state.loading ? (
                <tr>
                  <td colSpan="5">
                    <Text textAlign="center">
                      <Loader size="40px" style={{ display: "inline-block" }} />
                    </Text>
                  </td>
                </tr>
              ) : (
                availableHtml
              )}
            </tbody>
          </Table>
        </Card>
      </>
    );
  }
}
