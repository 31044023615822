import { theme } from "rimble-ui";

export default Object.assign(theme, {
  colors: {
    ...theme.colors,
    primary: "black"
  },
  fonts: {
    ...theme.fonts,
    sansSerif: "Inter, -apple-system, sans-serif"
  },
  radii: ["0", "5.7514px", "8.7514px", "16.7514px"],
  fontSizes: [8, 12, 14, 16, 20, 24, 32, 40],
  space: [0, 4, 8, 16, 32, 64, 128, 256]
});
