export const tokens = {
  1: {
    "0x0000000000000000000000000000000000000000": {
      Symbol: "ETH",
      Decimals: 18
    },
    "0x6b175474e89094c44da98b954eedeac495271d0f": {
      Symbol: "DAI",
      Decimals: 18
    },
    "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48": {
      Symbol: "USDC",
      Decimals: 18
    }
  },
  42: {
    "0x0000000000000000000000000000000000000000": {
      Symbol: "ETH",
      Decimals: 18
    },
    "0x9aa23c7d6be1052f1c23b39c100edd98c538c392": {
      Symbol: "DAI",
      Decimals: 18
    },
    "0x0E2D755F8a41054A5f3c06ddF910A04313Eb1995": {
      Symbol: "BAT",
      Decimals: 18
    }
  },
  50: {
    "0x0000000000000000000000000000000000000000": {
      Symbol: "ETH",
      Decimals: 18
    },
    "0x858EB3569b4D43fe1361716Ed9769895E30e2442": {
      Symbol: "DAI",
      Decimals: 18
    },
    "0xd3a189cD0655795fc22220E5d3b5a558348Cb812": {
      Symbol: "BAT",
      Decimals: 18
    }
  },
  10086: {
    "0x0000000000000000000000000000000000000000": {
      Symbol: "ETH",
      Decimals: 18
    },
    "0xA94B7f0465E98609391C623d0560C5720a3f2D33": {
      Symbol: "DAI",
      Decimals: 18
    }
  }
};

export const etherscanUrls = {
  1: "https://etherscan.io",
  3: "https://ropsten.etherscan.io",
  4: "https://rinkeby.etherscan.io",
  42: "https://kovan.etherscan.io",
  50: "https://development.etherscan.io"
};

// export const requiredNetwork = 42;

export const infuraProjectId = "54a3dbbcb58946f6bf9258b8ecec98bb";

export const vehicleBlacklist = [];

export const generatedBlocks = {
  1: {
    secondsPerBlock: 15,
    blocksPerYear: 2102400,
    blocksPerDay: 5760
  },
  3: {
    secondsPerBlock: 15,
    blocksPerYear: 2102400,
    blocksPerDay: 5760
  },
  4: {
    secondsPerBlock: 15,
    blocksPerYear: 2102400,
    blocksPerDay: 5760
  },
  42: {
    secondsPerBlock: 4,
    blocksPerYear: 7884000,
    blocksPerDay: 21600
  },
  50: {
    secondsPerBlock: 15,
    blocksPerYear: 2102400,
    blocksPerDay: 5760
  },
  10086: {
    secondsPerBlock: 4,
    blocksPerYear: 7884000,
    blocksPerDay: 21600
  }
};
