import React, { Fragment } from "react";
import {
  Box,
  Button,
  Card,
  Field,
  Flex,
  Input,
  Loader,
  Modal,
  Text
} from "rimble-ui";

export default class MemberEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      creditVehicles: {}
    };
  }

  onChangeValue(key, address, value) {
    let keyData = { ...this.state[key] };
    keyData[address] = value;
    this.setState({ [key]: keyData });
  }

  async onUpdate(creditVehicleAddress, decimals) {
    const { provider } = this.props;

    if (provider)
      await provider.setCreditLimit(
        creditVehicleAddress,
        this.props.data.member,
        this.state.creditVehicles[creditVehicleAddress],
        decimals
      );
  }

  render() {
    let {
        props: {
          isOpen,
          closeModal,
          loading,
          data: { limits, admins }
        },
        onChangeValue,
        onUpdate
      } = this,
      html;
    onChangeValue = onChangeValue.bind(this);
    onUpdate = onUpdate.bind(this);

    if (limits) {
      html = limits.map((v, i) => {
        return (
          <Fragment key={i}>
            <Flex mx={-2} mb={3} alignItems="flex-end">
              <Box px={2} flex={1}>
                <Field label={v.token} width="100%" mb={0}>
                  <Input
                    type="number"
                    size="small"
                    width="100%"
                    defaultValue={v.limit.toFixed(4)}
                    onChange={e =>
                      onChangeValue("creditVehicles", v.address, e.target.value)
                    }
                  />
                </Field>
              </Box>
              <Box px={2}>
                <Button
                  onClick={() => onUpdate(v.address, v.decimals)}
                  disabled={admins.indexOf(v.address) === -1}
                >
                  Confirm
                </Button>
              </Box>
            </Flex>
          </Fragment>
        );
      });
    }

    return (
      <React.Fragment>
        <Modal isOpen={isOpen}>
          <Card width={"660px"} p={0}>
            <Button.Text
              icononly
              icon={"Close"}
              color={"moon-gray"}
              position={"absolute"}
              top={0}
              right={0}
              mt={0}
              mr={0}
              onClick={closeModal}
            />
            <Box py={40} px={32}>
              {loading ? (
                <Text textAlign="center">
                  <Loader size="40px" style={{ display: "inline-block" }} />
                </Text>
              ) : (
                html
              )}
            </Box>
            <Flex
              px={4}
              py={3}
              borderTop={1}
              borderColor={"#E8E8E8"}
              justifyContent={"flex-end"}
            >
              <Button.Outline onClick={closeModal}>Cancel</Button.Outline>
            </Flex>
          </Card>
        </Modal>
      </React.Fragment>
    );
  }
}
