import dayjs from "dayjs";
import {
  etherscanUrls,
  tokens,
  vehicleBlacklist,
  generatedBlocks
} from "../config";
import CreditVehicle from "../contracts/creditVehicle";
import CreditVehicleFactory from "../contracts/creditVehicleFactory";
import ERC20Detailed from "../contracts/ERC20Detailed";
import FaucetERC20 from "../contracts/faucetERC20";
import MemberManager from "../contracts/memberManager";
import {
  calculationInterestRate,
  calculationOriginationFee,
  getQueryVariable,
  toBaseUnit
} from "../utils";

const WAD = 18;

function errorHandle(error) {
  const isDebug = getQueryVariable("debug");
  if (isDebug) {
    alert(error);
  }
}

class CreditVehicleProvider {
  constructor(web3Context) {
    this.active = false;
    if (!web3Context) return;
    const { networkId, lib: web3, accounts } = web3Context;
    this.networkId = networkId;
    this.accounts = accounts;
    console.log("CreditVehicleProvider", accounts);
    if (!web3) {
      errorHandle("There was an error connecting to web3");
    } else {
      this.web3 = web3;
      this.init(networkId);
    }
  }

  async init(networkId) {
    try {
      if (CreditVehicleFactory.address[networkId]) {
        this.creditVehicleFactory = new this.web3.eth.Contract(
          CreditVehicleFactory.abi,
          CreditVehicleFactory.address[networkId]
        );
        this.active = true;
        const memberManagerAddress = await this.creditVehicleFactory.methods
          .memberManager()
          .call();
        this.memberManager = new this.web3.eth.Contract(
          MemberManager.abi,
          memberManagerAddress
        );
      }
    } catch (error) {
      console.log(error);
      errorHandle(error);
    }
  }

  async getBlock(blocknumber) {
    return await this.web3.eth.getBlock(blocknumber);
  }

  async getTokenInfos(token) {
    if (this.networkId && tokens[this.networkId][token]) {
      const decimals = tokens[this.networkId][token].Decimals;
      const symbol = tokens[this.networkId][token].Symbol;

      return {
        decimals,
        symbol
      };
    } else {
      const IERC20 = new this.web3.eth.Contract(ERC20Detailed.abi, token);
      const decimals = await IERC20.methods.decimals().call();
      const symbol = await IERC20.methods.symbol().call();

      if (decimals && symbol) {
        return {
          decimals,
          symbol
        };
      } else {
        return {};
      }
    }
  }

  async createCreditVehicle({
    address,
    decimals,
    rate,
    fee,
    loanSizeMax,
    loanSizeMin,
    maxLateDuration,
    epochDuration
  }) {
    if (!this.active || !this.accounts[0]) return;
    const BN = this.web3.utils.BN;
    rate = calculationInterestRate(
      generatedBlocks[this.networkId]["blocksPerYear"],
      rate.toString(),
      WAD,
      BN
    ).toString();
    fee = calculationOriginationFee(fee.toString(), WAD, BN).toString();
    loanSizeMax = toBaseUnit(loanSizeMax.toString(), decimals, BN).toString();
    loanSizeMin = toBaseUnit(loanSizeMin.toString(), decimals, BN).toString();

    if (address != "0x0000000000000000000000000000000000000000") {
      console.log(address);
      const IERC20 = new this.web3.eth.Contract(ERC20Detailed.abi, address);
      try {
        await IERC20.methods.symbol().call();
      } catch (e) {
        const error = "address is not a erc20 address";
        console.log(error);
        errorHandle(error);
        return false;
      }
    }

    try {
      await this.creditVehicleFactory.methods
        .createVehicle(
          address,
          decimals,
          rate,
          fee,
          loanSizeMax,
          loanSizeMin,
          maxLateDuration,
          epochDuration
        )
        .send({ from: this.accounts[0] });
      window.toastProvider.addMessage("Created successfully", {
        variant: "success"
      });
      return true;
    } catch (error) {
      console.log(error);
      errorHandle(error);
      return false;
    }
  }

  async getCreditVehicles() {
    if (!this.active) return;
    let creditVehicles = [],
      allVehicles = [];
    try {
      allVehicles = await this.creditVehicleFactory.methods
        .getCreditVehicles()
        .call();
      console.log("allVehicles: ", allVehicles);
      allVehicles.forEach((v, i) => {
        if (vehicleBlacklist.indexOf(v) === -1) {
          creditVehicles.push(v);
        }
      });
      console.log("creditVehicles: ", creditVehicles);
    } catch (error) {
      console.log(error);
      errorHandle(error);
    } finally {
      return creditVehicles;
    }
  }

  async getCreditVehicleData(creditVehicleAddress) {
    console.log(`Loading creditVehicle data for ${creditVehicleAddress}`);
    if (!this.active) return;
    try {
      const creditVehicle = new this.web3.eth.Contract(
        CreditVehicle.abi,
        creditVehicleAddress
      );
      const poolToken = await creditVehicle.methods.poolToken().call();
      const interestRate = await creditVehicle.methods.interestRate().call();

      const originationFee = await creditVehicle.methods
        .originationFee()
        .call();
      const loanSizeMax = await creditVehicle.methods.loanSizeMax().call();
      const loanSizeMin = await creditVehicle.methods.loanSizeMin().call();
      const maxLateDuration = await creditVehicle.methods
        .maxLateDuration()
        .call();
      const epochDuration = await creditVehicle.methods.epochDuration().call();
      const tokenInfos = await this.getTokenInfos(poolToken);

      const decimals = tokenInfos.decimals;

      const lendingPool = await creditVehicle.methods.lendingPool().call();
      let poolBalance;
      if (tokenInfos.symbol === "ETH") {
        poolBalance = await this.web3.eth.getBalance(lendingPool);
      } else {
        const IERC20 = new this.web3.eth.Contract(ERC20Detailed.abi, poolToken);
        poolBalance = await IERC20.methods.balanceOf(lendingPool).call();
      }

      const totalBollowed = await this.getCreditVehicleTotalBorrowed(
        creditVehicleAddress
      );

      return {
        token: tokenInfos.symbol,
        decimals,
        tokenAddress: poolToken,
        interestRate:
          (parseFloat(interestRate) *
            generatedBlocks[this.networkId]["blocksPerYear"]) /
          10 ** WAD,
        ratePerBlock: parseFloat(interestRate) / 10 ** WAD,
        originationFee: parseFloat(originationFee) / 10 ** WAD,
        maxLateDuration,
        epochDuration,
        totalBollowed: totalBollowed
          ? parseFloat(totalBollowed) / 10 ** decimals
          : null,
        loanSizeMax: parseFloat(loanSizeMax) / 10 ** decimals,
        loanSizeMin: parseFloat(loanSizeMin) / 10 ** decimals,
        poolBalance: parseFloat(poolBalance) / 10 ** decimals
      };
    } catch (error) {
      console.log(error);
      errorHandle(error);
      return {};
    }
  }

  async getCreditVehicleTotalBorrowed(creditVehicleAddress) {
    if (!this.active) return;
    try {
      const creditVehicle = new this.web3.eth.Contract(
        CreditVehicle.abi,
        creditVehicleAddress
      );
      const members = await this.getMembers();
      let totalBorrowed = 0;
      const promises = members.map(async v => {
        const borrowed = await creditVehicle.methods
          .getCurrentBalanceOwed(v)
          .call();
        totalBorrowed += parseFloat(borrowed);
      });
      await Promise.all(promises);
      return totalBorrowed;
    } catch (error) {
      console.log(error);
      errorHandle(error);
      return null;
    }
  }

  async getMemberAllLimits(addressList, member) {
    if (addressList.length === 0 || !this.active || !member) return;
    let limitList = [];
    try {
      const promises = addressList.map(async address => {
        const creditVehicle = new this.web3.eth.Contract(
          CreditVehicle.abi,
          address
        );
        const limit = await creditVehicle.methods.getCreditLimit(member).call();
        const poolToken = await creditVehicle.methods.poolToken().call();
        const tokenInfos = await this.getTokenInfos(poolToken);
        const decimals = tokenInfos.decimals;
        const token = tokenInfos.symbol;
        limitList.push({
          address,
          decimals,
          limit: parseFloat(limit) / 10 ** decimals,
          token
        });
      });
      await Promise.all(promises);

      return limitList;
    } catch (error) {
      console.log(error);
      errorHandle(error);
      return [];
    }
  }

  async getCreditVehicleToken(creditVehicleAddress) {
    if (!this.active) return;
    try {
      const creditVehicle = new this.web3.eth.Contract(
        CreditVehicle.abi,
        creditVehicleAddress
      );
      const poolToken = await creditVehicle.methods.poolToken().call();
      const tokenInfos = await this.getTokenInfos(poolToken);
      const token = tokenInfos.symbol;
      return token;
    } catch (error) {
      console.log(error);
      errorHandle(error);
      return {};
    }
  }

  async getUserData(creditVehicleAddress, account) {
    console.log(
      `Loading user data for ${account} on credit ${creditVehicleAddress}`
    );
    if (!account || !this.active) return {};
    const creditVehicle = new this.web3.eth.Contract(
      CreditVehicle.abi,
      creditVehicleAddress
    );

    let creditLimit = 0,
      remainingCreditLimit = 0,
      borrowed = 0,
      isOverdue = false,
      interest = 0,
      overdueDate = "-",
      decimals = 18;

    try {
      creditLimit = await creditVehicle.methods.getCreditLimit(account).call();
      remainingCreditLimit = await creditVehicle.methods
        .getRemainingCreditLimit(account)
        .call();
      borrowed = await creditVehicle.methods
        .getCurrentBalanceOwed(account)
        .call();
      isOverdue = await creditVehicle.methods.checkIsOverdue(account).call();
      interest = await creditVehicle.methods
        .calculatingInterest(account)
        .call();
      const blockNumber = await creditVehicle.methods
        .getBlockNumber(account)
        .call();
      const block = await this.web3.eth.getBlock(blockNumber);
      const lastPaymentEpoch = await creditVehicle.methods
        .getLastPaymentEpoch(account)
        .call();
      const startBlockNumber = await creditVehicle.methods
        .startBlockNumber()
        .call();
      const epochDuration = await creditVehicle.methods.epochDuration().call();
      const maxLateDuration = await creditVehicle.methods
        .maxLateDuration()
        .call();

      const poolToken = await creditVehicle.methods.poolToken().call();
      const tokenInfos = await this.getTokenInfos(poolToken);
      decimals = tokenInfos.decimals;

      const curBlockNumber = await this.web3.eth.getBlockNumber();
      const overdueBlockNumber =
        parseInt(startBlockNumber) +
        (parseInt(lastPaymentEpoch) + parseInt(maxLateDuration)) *
          parseInt(epochDuration);

      if (parseFloat(borrowed) > 0) {
        const diffBlock = Math.abs(overdueBlockNumber - curBlockNumber);
        const secondsPerBlock =
          generatedBlocks[this.networkId]["secondsPerBlock"];
        if (overdueBlockNumber > curBlockNumber) {
          if (diffBlock >= generatedBlocks[this.networkId]["blocksPerDay"]) {
            overdueDate = `In ${((diffBlock * secondsPerBlock) / 86400).toFixed(
              1
            )} days`;
          } else {
            overdueDate = `In ${((diffBlock * secondsPerBlock) / 3600).toFixed(
              1
            )} hours`;
          }
        } else {
          if (diffBlock >= generatedBlocks[this.networkId]["blocksPerDay"]) {
            overdueDate = `Overdue for ${(
              (diffBlock * secondsPerBlock) /
              86400
            ).toFixed(1)} days`;
          } else {
            overdueDate = `Overdue for ${(
              (diffBlock * secondsPerBlock) /
              3600
            ).toFixed(1)} hours`;
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
    return {
      creditLimit: parseFloat(creditLimit) / 10 ** decimals,
      remainingCreditLimit: parseFloat(remainingCreditLimit) / 10 ** decimals,
      borrowed: parseFloat(borrowed) / 10 ** decimals,
      interest: parseFloat(interest) / 10 ** decimals,
      overdueDate,
      isOverdue
    };
  }

  async balanceOf(creditVehicleAddress) {
    if (!this.accounts[0] || !this.active) return;
    const creditVehicle = new this.web3.eth.Contract(
      CreditVehicle.abi,
      creditVehicleAddress
    );
    const poolToken = await creditVehicle.methods.poolToken().call();
    const tokenInfos = await this.getTokenInfos(poolToken);
    const decimals = tokenInfos.decimals;
    const contractBalance = await creditVehicle.methods
      .balanceOf(this.accounts[0])
      .call();
    let walletBalance;
    if (tokenInfos.symbol === "ETH") {
      walletBalance = await this.web3.eth.getBalance(this.accounts[0]);
    } else {
      const IERC20 = new this.web3.eth.Contract(ERC20Detailed.abi, poolToken);
      walletBalance = await IERC20.methods.balanceOf(this.accounts[0]).call();
    }

    return {
      contractBalance: contractBalance / 10 ** decimals,
      walletBalance: walletBalance / 10 ** decimals
    };
  }

  async deposit(creditVehicleAddress, amount) {
    if (!this.accounts[0] || !this.active) return;
    const BN = this.web3.utils.BN;
    const creditVehicle = new this.web3.eth.Contract(
      CreditVehicle.abi,
      creditVehicleAddress
    );
    const poolToken = await creditVehicle.methods.poolToken().call();
    const tokenInfos = await this.getTokenInfos(poolToken);
    const decimals = tokenInfos.decimals;
    const token = tokenInfos.symbol;
    const depositAmount = toBaseUnit(
      amount.toString(),
      decimals,
      BN
    ).toString();
    try {
      let tx;
      if (token === "ETH") {
        tx = await creditVehicle.methods
          .deposit(depositAmount)
          .send({ from: this.accounts[0], value: depositAmount });
      } else {
        const IERC20 = new this.web3.eth.Contract(ERC20Detailed.abi, poolToken);
        await IERC20.methods
          .approve(creditVehicleAddress, depositAmount)
          .send({ from: this.accounts[0] });
        tx = await creditVehicle.methods
          .deposit(depositAmount)
          .send({ from: this.accounts[0] });
      }
      window.toastProvider.addMessage("Processing deposit", {
        secondaryMessage: "Check progress on Etherscan",
        actionHref: etherscanUrls[this.networkId] + "/tx/" + tx.transactionHash,
        actionText: "Check",
        variant: "processing"
      });
    } catch (error) {
      console.log(error);
      errorHandle(error);
      window.toastProvider.addMessage(JSON.stringify(error.message));
    }
  }

  async withdraw(creditVehicleAddress, amount) {
    if (!this.accounts[0] || !this.active) return;
    const BN = this.web3.utils.BN;
    const creditVehicle = new this.web3.eth.Contract(
      CreditVehicle.abi,
      creditVehicleAddress
    );
    const poolToken = await creditVehicle.methods.poolToken().call();
    const tokenInfos = await this.getTokenInfos(poolToken);
    const decimals = tokenInfos.decimals;
    const token = tokenInfos.symbol;
    const withdrawAmount = toBaseUnit(
      amount.toString(),
      decimals,
      BN
    ).toString();
    try {
      let tx;
      if (token === "ETH") {
        tx = await creditVehicle.methods
          .withdraw(withdrawAmount)
          .send({ from: this.accounts[0] });
      } else {
        tx = await creditVehicle.methods
          .withdraw(withdrawAmount)
          .send({ from: this.accounts[0] });
      }
      window.toastProvider.addMessage("Processing withdraw", {
        secondaryMessage: "Check progress on Etherscan",
        actionHref: etherscanUrls[this.networkId] + "/tx/" + tx.transactionHash,
        actionText: "Check",
        variant: "processing"
      });
    } catch (error) {
      console.log(error);
      window.toastProvider.addMessage(JSON.stringify(error.message));
    }
  }

  async borrow(creditVehicleAddress, account, amount) {
    if (!account || !this.active) return;
    const BN = this.web3.utils.BN;
    const creditVehicle = new this.web3.eth.Contract(
      CreditVehicle.abi,
      creditVehicleAddress
    );
    const poolToken = await creditVehicle.methods.poolToken().call();
    const tokenInfos = await this.getTokenInfos(poolToken);
    const decimals = tokenInfos.decimals;
    const token = tokenInfos.symbol;
    const borrowAmount = toBaseUnit(amount.toString(), decimals, BN).toString();
    try {
      let tx;
      if (token === "ETH") {
        tx = await creditVehicle.methods
          .borrow(borrowAmount)
          .send({ from: account });
      } else {
        tx = await creditVehicle.methods
          .borrow(borrowAmount)
          .send({ from: account });
      }
      window.toastProvider.addMessage("Processing borrow", {
        secondaryMessage: "Check progress on Etherscan",
        actionHref: etherscanUrls[this.networkId] + "/tx/" + tx.transactionHash,
        actionText: "Check",
        variant: "processing"
      });
    } catch (error) {
      console.log(error);
      window.toastProvider.addMessage(JSON.stringify(error.message));
    }
  }

  async repay(creditVehicleAddress, account, amount) {
    if (!account || !this.active) return;
    const BN = this.web3.utils.BN;
    const creditVehicle = new this.web3.eth.Contract(
      CreditVehicle.abi,
      creditVehicleAddress
    );
    const poolToken = await creditVehicle.methods.poolToken().call();
    const tokenInfos = await this.getTokenInfos(poolToken);
    const decimals = tokenInfos.decimals;
    const token = tokenInfos.symbol;
    const repayAmount = toBaseUnit(amount.toString(), decimals, BN).toString();
    try {
      let tx;
      if (token === "ETH") {
        console.log("repayAmount", repayAmount);
        tx = await creditVehicle.methods
          .repay(repayAmount)
          .send({ from: account, value: repayAmount });
      } else {
        const IERC20 = new this.web3.eth.Contract(ERC20Detailed.abi, poolToken);
        const allowance = await IERC20.methods
          .allowance(account, creditVehicleAddress)
          .call();
        if (parseFloat(allowance) < parseFloat(repayAmount)) {
          await IERC20.methods
            .approve(creditVehicleAddress, "-1")
            .send({ from: account });
        }
        tx = await creditVehicle.methods
          .repay(repayAmount)
          .send({ from: account });
      }
      window.toastProvider.addMessage("Processing repay", {
        secondaryMessage: "Check progress on Etherscan",
        actionHref: etherscanUrls[this.networkId] + "/tx/" + tx.transactionHash,
        actionText: "Check",
        variant: "processing"
      });
    } catch (error) {
      console.log(error);
      window.toastProvider.addMessage(JSON.stringify(error.message));
    }
  }

  async getBalance(creditVehicleAddress, account) {
    if (!account || !this.active) return;
    const creditVehicle = new this.web3.eth.Contract(
      CreditVehicle.abi,
      creditVehicleAddress
    );
    const poolToken = await creditVehicle.methods.poolToken().call();
    const tokenInfos = await this.getTokenInfos(poolToken);
    const decimals = tokenInfos.decimals;
    let balance;
    if (tokenInfos.symbol === "ETH") {
      balance = await this.web3.eth.getBalance(account);
    } else {
      const IERC20 = new this.web3.eth.Contract(ERC20Detailed.abi, poolToken);
      balance = await await IERC20.methods.balanceOf(account).call();
    }
    return balance / 10 ** decimals;
  }

  async getFactoryAdmin() {
    if (!this.active) return;
    let address;
    try {
      address = await this.creditVehicleFactory.methods.admin().call();
    } catch (error) {
      console.log(error);
      errorHandle(error);
    } finally {
      return address;
    }
  }

  async setFactoryAdmin(address) {
    if (!this.active || !this.accounts[0]) return;
    try {
      await this.creditVehicleFactory.methods
        .setAdmin(address)
        .send({ from: this.accounts[0] });
      window.toastProvider.addMessage("Admin updated", {
        variant: "success"
      });
    } catch (error) {
      console.log(error);
      errorHandle(error);
    }
  }

  async addMember(address, key) {
    if (!this.active || !this.accounts[0]) return;
    //key not used for the alpha
    if (!key) key = "0x000000000000000000000000000000000000000001";
    try {
      await this.memberManager.methods
        .addMember(address, key)
        .send({ from: this.accounts[0] });
      window.toastProvider.addMessage("Member added successfully", {
        variant: "success"
      });
    } catch (error) {
      console.log(error);
      errorHandle(error);
    }
  }

  async removeMember(address) {
    if (!this.active || !this.accounts[0]) return;
    try {
      await this.memberManager.methods
        .removeMember(address)
        .send({ from: this.accounts[0] });
      window.toastProvider.addMessage("Member removed", {
        variant: "success"
      });
    } catch (error) {
      console.log(error);
      errorHandle(error);
    }
  }

  async isMember(account) {
    if (!this.active || !account) return;

    try {
      if (!this.memberManager) {
        const memberManagerAddress = await this.creditVehicleFactory.methods
          .memberManager()
          .call();

        this.memberManager = new this.web3.eth.Contract(
          MemberManager.abi,
          memberManagerAddress
        );
      }
      const isMember = await this.memberManager.methods
        .isMember(account)
        .call();
      return isMember;
    } catch (error) {
      console.log(error);
      errorHandle(error);
      return null;
    }
  }

  async setInterestRate(creditVehicleAddress, amount, decimals) {
    if (!creditVehicleAddress || !this.active || !this.accounts[0]) return;
    try {
      const BN = this.web3.utils.BN;
      amount = calculationInterestRate(
        generatedBlocks[this.networkId]["blocksPerYear"],
        amount.toString(),
        WAD,
        BN
      ).toString();
      const creditVehicle = new this.web3.eth.Contract(
        CreditVehicle.abi,
        creditVehicleAddress
      );
      await creditVehicle.methods
        .setInterestRate(amount)
        .send({ from: this.accounts[0] });
      window.toastProvider.addMessage("Interest updated", {
        variant: "success"
      });
      return true;
    } catch (error) {
      console.log(error);
      errorHandle(error);
      return false;
    }
  }

  async setOriginationFee(creditVehicleAddress, amount, decimals) {
    if (!creditVehicleAddress || !this.active || !this.accounts[0]) return;
    try {
      const BN = this.web3.utils.BN;
      amount = calculationOriginationFee(amount.toString(), WAD, BN).toString();
      const creditVehicle = new this.web3.eth.Contract(
        CreditVehicle.abi,
        creditVehicleAddress
      );
      await creditVehicle.methods
        .setOriginationFee(amount)
        .send({ from: this.accounts[0] });
      window.toastProvider.addMessage("Origination fee updated", {
        variant: "success"
      });
      return true;
    } catch (error) {
      console.log(error);
      errorHandle(error);
      return false;
    }
  }

  async setLoanSizeMax(creditVehicleAddress, amount, decimals) {
    if (!creditVehicleAddress || !this.active || !this.accounts[0]) return;
    try {
      const BN = this.web3.utils.BN;
      amount = toBaseUnit(amount.toString(), decimals, BN).toString();
      const creditVehicle = new this.web3.eth.Contract(
        CreditVehicle.abi,
        creditVehicleAddress
      );
      await creditVehicle.methods
        .setLoanSizeMax(amount)
        .send({ from: this.accounts[0] });
      window.toastProvider.addMessage("Loan max updated", {
        variant: "success"
      });
      return true;
    } catch (error) {
      console.log(error);
      errorHandle(error);
      return false;
    }
  }

  async setLoanSizeMin(creditVehicleAddress, amount, decimals) {
    if (!creditVehicleAddress || !this.active || !this.accounts[0]) return;
    try {
      const BN = this.web3.utils.BN;
      amount = toBaseUnit(amount.toString(), decimals, BN).toString();
      const creditVehicle = new this.web3.eth.Contract(
        CreditVehicle.abi,
        creditVehicleAddress
      );
      await creditVehicle.methods
        .setLoanSizeMin(amount)
        .send({ from: this.accounts[0] });
      window.toastProvider.addMessage("Loan min updated", {
        variant: "success"
      });
      return true;
    } catch (error) {
      console.log(error);
      errorHandle(error);
      return false;
    }
  }

  async setMaxLateDuration(creditVehicleAddress, amount) {
    if (!creditVehicleAddress || !this.active || !this.accounts[0]) return;
    try {
      const creditVehicle = new this.web3.eth.Contract(
        CreditVehicle.abi,
        creditVehicleAddress
      );
      await creditVehicle.methods
        .setMaxLateDuration(amount)
        .send({ from: this.accounts[0] });
      window.toastProvider.addMessage("Loan duration updated", {
        variant: "success"
      });
      return true;
    } catch (error) {
      console.log(error);
      errorHandle(error);
      return false;
    }
  }

  async setEpochDuration(creditVehicleAddress, amount) {
    if (!creditVehicleAddress || !this.active || !this.accounts[0]) return;
    try {
      const creditVehicle = new this.web3.eth.Contract(
        CreditVehicle.abi,
        creditVehicleAddress
      );
      await creditVehicle.methods
        .setEpochDuration(amount)
        .send({ from: this.accounts[0] });
      window.toastProvider.addMessage("Loan epoch updated", {
        variant: "success"
      });
      return true;
    } catch (error) {
      console.log(error);
      errorHandle(error);
      return false;
    }
  }

  async setCreditLimit(creditVehicleAddress, account, limit, decimals) {
    if (!creditVehicleAddress || !this.active || !this.accounts[0]) return;
    try {
      const BN = this.web3.utils.BN;
      limit = toBaseUnit(limit.toString(), decimals, BN).toString();
      const creditVehicle = new this.web3.eth.Contract(
        CreditVehicle.abi,
        creditVehicleAddress
      );
      await creditVehicle.methods
        .setCreditLimit(account, limit)
        .send({ from: this.accounts[0] });
      window.toastProvider.addMessage("Credit limit updated", {
        variant: "success"
      });
      return true;
    } catch (error) {
      console.log(error);
      errorHandle(error);
      return false;
    }
  }

  //0 borrowed, 1 asset, 2 interest, 3 apr, 4 limit, 5 isOverdue, 6 blockNumber
  async getLoans(addressList) {
    if (addressList.length === 0 || !this.active) return;
    let loanList = [];
    const getTokenInfos = this.getTokenInfos.bind(this);
    const getBlock = this.getBlock.bind(this);
    try {
      const members = await this.getMembers();
      const promises = addressList.map(async address => {
        const creditVehicle = new this.web3.eth.Contract(
          CreditVehicle.abi,
          address
        );
        const loanPromises = members.map(async member => {
          const loan = await creditVehicle.methods.getLoan(member).call();
          if (loan[0] > 0) {
            let loanObj = {};
            const tokenInfos = await getTokenInfos(loan[1]);
            const decimals = tokenInfos.decimals;
            loanObj["amount"] = parseFloat(loan[0]) / 10 ** decimals;
            loanObj["apr"] =
              (parseFloat(loan[3]) *
                generatedBlocks[this.networkId]["blocksPerYear"]) /
              10 ** WAD;
            loanObj["token"] = tokenInfos.symbol;
            loanObj["interest"] = parseFloat(loan[2]) / 10 ** decimals;
            loanObj["limit"] = parseFloat(loan[4]) / 10 ** decimals;
            loanObj["isOverdue"] = loan[5];
            loanObj["member"] = member;
            if (parseFloat(loan[6]) > 0) {
              const block = await getBlock(loan[6]);
              loanObj["date"] = dayjs(block.timestamp * 1000).format(
                "YYYY-MM-DD HH:mm"
              );
            } else {
              loanObj["date"] = "-";
            }
            loanList.push(loanObj);
          }
        });
        await Promise.all(loanPromises);
      });
      await Promise.all(promises);
      return loanList;
    } catch (error) {
      console.log(error);
      errorHandle(error);
      return [];
    }
  }

  async getMembers() {
    if (!this.active) return;
    if (!this.memberManager) {
      const memberManagerAddress = await this.creditVehicleFactory.methods
        .memberManager()
        .call();
      this.memberManager = new this.web3.eth.Contract(
        MemberManager.abi,
        memberManagerAddress
      );
    }

    const members = await this.memberManager.methods.getMembers().call();
    return members;
  }

  async addMemberAdmin(address) {
    if (!this.active) return;
    if (!this.memberManager) {
      const memberManagerAddress = await this.creditVehicleFactory.methods
        .memberManager()
        .call();
      this.memberManager = new this.web3.eth.Contract(
        MemberManager.abi,
        memberManagerAddress
      );
    }
    try {
      await this.memberManager.methods
        .addAdmin(address)
        .send({ from: this.accounts[0] });
      window.toastProvider.addMessage("Admin added", {
        variant: "success"
      });
      return true;
    } catch (error) {
      console.log(error);
      errorHandle(error);
      return false;
    }
  }

  async removeMemberAdmin(address) {
    if (!this.active) return;
    if (!this.memberManager) {
      const memberManagerAddress = await this.creditVehicleFactory.methods
        .memberManager()
        .call();
      this.memberManager = new this.web3.eth.Contract(
        MemberManager.abi,
        memberManagerAddress
      );
    }
    try {
      await this.memberManager.methods
        .removeAdmin(address)
        .send({ from: this.accounts[0] });
      window.toastProvider.addMessage("Admin removed", {
        variant: "success"
      });
      return true;
    } catch (error) {
      console.log(error);
      errorHandle(error);
      return false;
    }
  }

  async getMemberAdmins() {
    if (!this.active) return;
    if (!this.memberManager) {
      const memberManagerAddress = await this.creditVehicleFactory.methods
        .memberManager()
        .call();
      this.memberManager = new this.web3.eth.Contract(
        MemberManager.abi,
        memberManagerAddress
      );
    }
    const admins = await this.memberManager.methods.getAdmins().call();
    return admins;
  }

  async isMemberSuperAdmin() {
    if (!this.active || !this.accounts[0]) return;
    if (!this.memberManager) {
      const memberManagerAddress = await this.creditVehicleFactory.methods
        .memberManager()
        .call();
      this.memberManager = new this.web3.eth.Contract(
        MemberManager.abi,
        memberManagerAddress
      );
    }
    const isSuperAdmin = await this.memberManager.methods
      .isSuperAdmin(this.accounts[0])
      .call();
    return isSuperAdmin;
  }

  async isMemberAdmin() {
    if (!this.active || !this.accounts[0]) return;
    if (!this.memberManager) {
      const memberManagerAddress = await this.creditVehicleFactory.methods
        .memberManager()
        .call();
      this.memberManager = new this.web3.eth.Contract(
        MemberManager.abi,
        memberManagerAddress
      );
    }
    const isAdmin = await this.memberManager.methods
      .isAdmin(this.accounts[0])
      .call();
    const isSuperAdmin = await this.memberManager.methods
      .isSuperAdmin(this.accounts[0])
      .call();
    return isAdmin || isSuperAdmin;
  }

  async addAdmin(creditVehicleAddress, address) {
    if (!this.active) return;
    const creditVehicle = new this.web3.eth.Contract(
      CreditVehicle.abi,
      creditVehicleAddress
    );
    try {
      await creditVehicle.methods
        .addAdmin(address)
        .send({ from: this.accounts[0] });
      window.toastProvider.addMessage("Admin added", {
        variant: "success"
      });
      return true;
    } catch (error) {
      console.log(error);
      errorHandle(error);
      return false;
    }
  }

  async removeAdmin(creditVehicleAddress, address) {
    if (!this.active) return;
    const creditVehicle = new this.web3.eth.Contract(
      CreditVehicle.abi,
      creditVehicleAddress
    );
    try {
      await creditVehicle.methods
        .removeAdmin(address)
        .send({ from: this.accounts[0] });
      window.toastProvider.addMessage("Admin removed", {
        variant: "success"
      });
      return true;
    } catch (error) {
      console.log(error);
      errorHandle(error);
      return false;
    }
  }

  async getAdmins(creditVehicleAddress) {
    if (!this.active) return;
    const creditVehicle = new this.web3.eth.Contract(
      CreditVehicle.abi,
      creditVehicleAddress
    );
    const admins = await creditVehicle.methods.getAdmins().call();
    return admins;
  }

  async isSuperAdmin(creditVehicleAddress) {
    if (!this.active || !this.accounts[0]) return;
    const creditVehicle = new this.web3.eth.Contract(
      CreditVehicle.abi,
      creditVehicleAddress
    );
    const isSuperAdmin = await creditVehicle.methods
      .isSuperAdmin(this.accounts[0])
      .call();
    return isSuperAdmin;
  }

  async isAdmin(creditVehicleAddress) {
    if (!this.active || !this.accounts[0]) return;
    const creditVehicle = new this.web3.eth.Contract(
      CreditVehicle.abi,
      creditVehicleAddress
    );
    const isAdmin = await creditVehicle.methods
      .isAdmin(this.accounts[0])
      .call();
    const isSuperAdmin = await creditVehicle.methods
      .isSuperAdmin(this.accounts[0])
      .call();
    return isAdmin || isSuperAdmin;
  }

  async addCreditVehicleAdmin(creditVehicleAddress, account) {
    if (!creditVehicleAddress || !this.active || !this.accounts[0]) return;
    try {
      const creditVehicle = new this.web3.eth.Contract(
        CreditVehicle.abi,
        creditVehicleAddress
      );
      await creditVehicle.methods
        .addAdmin(account)
        .send({ from: this.accounts[0] });
      window.toastProvider.addMessage("Admin added", {
        variant: "success"
      });
      return true;
    } catch (error) {
      console.log(error);
      errorHandle(error);
      return false;
    }
  }

  async updateCreditVehicleParams({
    address,
    decimals,
    rate,
    fee,
    loanSizeMax,
    loanSizeMin,
    maxLateDuration,
    epochDuration
  }) {
    if (!this.active || !this.accounts[0] || !address) return;
    try {
      const BN = this.web3.utils.BN;
      const creditVehicle = new this.web3.eth.Contract(
        CreditVehicle.abi,
        address
      );
      rate = calculationInterestRate(
        generatedBlocks[this.networkId]["blocksPerYear"],
        rate.toString(),
        WAD,
        BN
      ).toString();
      fee = calculationOriginationFee(fee.toString(), WAD, BN).toString();
      loanSizeMax = toBaseUnit(loanSizeMax.toString(), decimals, BN).toString();
      loanSizeMin = toBaseUnit(loanSizeMin.toString(), decimals, BN).toString();
      await creditVehicle.methods
        .setParams(
          rate,
          fee,
          loanSizeMax,
          loanSizeMin,
          maxLateDuration,
          epochDuration
        )
        .send({ from: this.accounts[0] });
      window.toastProvider.addMessage("Credit params updated", {
        variant: "success"
      });
      return true;
    } catch (error) {
      console.log(error);
      errorHandle(error);
      return false;
    }
  }

  async faucet(address, amount) {
    if (!this.accounts[0] || !address) return;
    const faucetERC20 = new this.web3.eth.Contract(FaucetERC20.abi, address);
    await faucetERC20.methods
      .mint(this.accounts[0], amount)
      .send({ from: this.accounts[0] });
  }
}

export default CreditVehicleProvider;
