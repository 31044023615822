import NetworkIndicator from "@rimble/network-indicator";
import React, { useEffect, useState, useCallback } from "react";
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import { useHistory } from "react-router-dom";
import { Box, Button, Card, EthAddress, Flex, Heading } from "rimble-ui";
import styled from "styled-components";
// import { requiredNetwork } from "../../config.js";

const DropdownWrapper = styled(Flex).attrs({ justifyContent: "flex-end" })`
  position: relative;
`;

const DropdownContainer = styled(Card).attrs({ p: 0 })`
  position: absolute;
  z-index: 1;
  top: 64px;
`;

const Menu = ({ web3Context, networkId }) => {
  const [open, setOpen] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [balance, setBalance] = useState(0);

  const { push } = useHistory();

  const { accounts, lib } = web3Context;

  const address = accounts && accounts.length > 0 && accounts[0];

  const getBalance = useCallback(async () => {
    const bal = !!address
      ? lib.utils.fromWei(await lib.eth.getBalance(address), "ether")
      : 0;

    setBalance(bal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    getBalance();
  }, [getBalance, web3Context]);

  const toggle = e => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(!open);
  };

  const menuCloseEvent = e => {
    if (
      open &&
      e.target.localName != "button" &&
      e.target.localName != "input"
    ) {
      setOpen(false);
    }
  };

  window.removeEventListener("click", menuCloseEvent);
  window.addEventListener("click", menuCloseEvent);

  return (
    <>
      <DropdownWrapper justifyContent="flex-end">
        <Card
          as="button"
          onClick={toggle}
          padding="2px"
          height={48}
          width={48}
          borderRadius={99999}
          borderColor="primary"
          borderWidth={2}
        >
          {!!address && (
            <Jazzicon diameter={40} seed={jsNumberForAddress(address)} />
          )}
        </Card>

        {open && (
          <DropdownContainer>
            <Flex
              px={24}
              py={16}
              justifyContent="space-between"
              alignItems="center"
            >
              <Heading.h3>My Account</Heading.h3>

              <Button onClick={e => push("/manage")} size="small">
                Manage
              </Button>
            </Flex>
            <Box pb={24} pt={24 - 1} px={24} borderTop="1px solid #eee">
              <Box mb={24}>
                <EthAddress address={address} />
              </Box>

              <NetworkIndicator currentNetwork={networkId} />
            </Box>
          </DropdownContainer>
        )}
      </DropdownWrapper>
    </>
  );
};

const Header = ({ web3Context, networkId }) => {
  const { accounts } = web3Context;

  return (
    <Box maxWidth={1280 + 32} mx="auto" px={32}>
      <Flex py={4} width="100%" alignItems="center">
        <Box flex={1} />

        <Box flex={1}>
          <Heading textAlign="center">Credit Lines</Heading>
        </Box>

        <Box flex={1}>
          {accounts && accounts.length ? (
            <Menu web3Context={web3Context} networkId={networkId} />
          ) : null}
        </Box>
      </Flex>
    </Box>
  );
};
export default Header;
