import React from "react";
import { Box, Button, Card, Flex, Heading, Input, Modal } from "rimble-ui";

export default class MemberAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      address: "",
      key: ""
    };
  }

  onChangeValue(key, v) {
    this.setState({
      [key]: v.target.value
    });
  }

  async create() {
    const { provider } = this.props;
    if (provider) {
      await provider.addMember(this.state.address, this.state.key);
    }
  }

  render() {
    const { isOpen, closeModal } = this.props;
    return (
      <React.Fragment>
        <Modal isOpen={isOpen}>
          <Card width={"660px"} p={0}>
            <Button.Text
              icononly
              icon={"Close"}
              color={"moon-gray"}
              position={"absolute"}
              top={0}
              right={0}
              mt={0}
              mr={0}
              onClick={closeModal}
            />
            <Box py={40} px={32}>
              {" "}
              <Flex>
                <Box p={3} width={1 / 2}>
                  <Heading.h3>Member address</Heading.h3>
                  <Input
                    type="text"
                    size="small"
                    placeholder=""
                    width="100%"
                    onChange={this.onChangeValue.bind(this, "address")}
                  />
                </Box>
                <Box p={3} width={1 / 2}>
                  <Heading.h3>Key</Heading.h3>
                  <Input
                    type="text"
                    size="small"
                    placeholder=""
                    width="100%"
                    onChange={this.onChangeValue.bind(this, "key")}
                  />
                </Box>
              </Flex>
            </Box>
            <Flex
              px={4}
              py={3}
              borderTop={1}
              borderColor={"#E8E8E8"}
              justifyContent={"flex-end"}
            >
              <Button.Outline onClick={closeModal}>Cancel</Button.Outline>
              <Button ml={3} onClick={this.create.bind(this)}>
                Create
              </Button>
            </Flex>
          </Card>
        </Modal>
      </React.Fragment>
    );
  }
}
