import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch
} from "react-router-dom";
import { Box, Button, Flex, Heading, Loader, Text } from "rimble-ui";
import Admins from "../components/Admins";
import Loans from "../components/Loans";
import Markets from "../components/Markets";
import Members from "../components/Members";
// import AdminsCreditVehicle from '../components/AdminsCreditVehicle';

const Manage = ({ creditVehicleProvider, accounts }) => {
  const [creditVehicles, setCreditVehicles] = useState([]);
  const [loading, setLoading] = useState(true);

  const { url, path } = useRouteMatch();
  const { pathname } = useLocation();
  const { push } = useHistory();

  useEffect(() => {
    const loadData = async () => {
      const server = creditVehicleProvider;
      if (server && server.active) {
        const creditVehicles = await server.getCreditVehicles();
        setCreditVehicles(creditVehicles);
        setLoading(false);
      }
    };

    loadData();
  }, [creditVehicleProvider]);

  return (
    <>
      <Flex alignItems="center" mb={4}>
        <Button
          icon="KeyboardArrowLeft"
          onClick={() => push("/")}
          mr={4}
          size="small"
        >
          Back home
        </Button>{" "}
        <Heading.h1>Manage</Heading.h1>
      </Flex>

      <Flex>
        <Box flex={1}>
          <Link to={`${url}/markets`}>
            <Box
              p={3}
              borderBottom="2px solid transparent"
              borderColor={
                pathname === `${url}/markets` ? "primary" : "transparent"
              }
            >
              <Heading.h4 textAlign="center">Markets</Heading.h4>
            </Box>
          </Link>
        </Box>

        <Box flex={1}>
          <Link to={`${url}/loans`}>
            <Box
              p={3}
              borderBottom="2px solid transparent"
              borderColor={
                pathname === `${url}/loans` ? "primary" : "transparent"
              }
            >
              <Heading.h4 textAlign="center">Loans</Heading.h4>
            </Box>
          </Link>
        </Box>

        <Box flex={1}>
          <Link to={`${url}/members`}>
            <Box
              p={3}
              borderBottom="2px solid transparent"
              borderColor={
                pathname === `${url}/members` ? "primary" : "transparent"
              }
            >
              <Heading.h4 textAlign="center">Members</Heading.h4>
            </Box>
          </Link>
        </Box>

        <Box flex={1}>
          <Link to={`${url}/admins`}>
            <Box
              p={3}
              borderBottom="2px solid transparent"
              borderColor={
                pathname === `${url}/admins` ? "primary" : "transparent"
              }
            >
              <Heading.h4 textAlign="center">Admins</Heading.h4>
            </Box>
          </Link>
        </Box>
      </Flex>

      <Switch>
        <Route path={`${path}/`} exact>
          <Redirect to={`${path}/markets`} />
        </Route>

        <Route path={`${path}/markets`} exact>
          {loading ? (
            <Text textAlign="center">
              <Loader size="40px" style={{ display: "inline-block" }} />
            </Text>
          ) : (
            <Markets
              creditVehicleProvider={creditVehicleProvider}
              accounts={accounts}
              creditVehicles={creditVehicles}
            />
          )}
        </Route>

        <Route path={`${path}/loans`} exact>
          {loading ? (
            <Text textAlign="center">
              <Loader size="40px" style={{ display: "inline-block" }} />
            </Text>
          ) : (
            <Loans
              creditVehicleProvider={creditVehicleProvider}
              accounts={accounts}
              creditVehicles={creditVehicles}
            />
          )}
        </Route>

        <Route path={`${path}/members`} exact>
          {loading ? (
            <Text textAlign="center">
              <Loader size="40px" style={{ display: "inline-block" }} />
            </Text>
          ) : (
            <Members
              creditVehicleProvider={creditVehicleProvider}
              accounts={accounts}
              creditVehicles={creditVehicles}
            />
          )}
        </Route>

        <Route path={`${path}/admins`} exact>
          {loading ? (
            <Text textAlign="center">
              <Loader size="40px" style={{ display: "inline-block" }} />
            </Text>
          ) : (
            <Admins
              creditVehicleProvider={creditVehicleProvider}
              accounts={accounts}
              creditVehicles={creditVehicles}
            />
          )}
        </Route>

        {/* <Route path={`${path}/admins/:address`} exact>
          {loading ? (
            <Text textAlign="center">
              <Loader size="40px" style={{ display: 'inline-block' }} />
            </Text>
          ) : (
            <AdminsCreditVehicle creditVehicleProvider={creditVehicleProvider} accounts={accounts} />
          )}
        </Route> */}
      </Switch>
    </>
  );
};

export default Manage;
