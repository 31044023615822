import React from "react";
import { useCopyToClipboard } from "react-use";
import { Button, Tooltip } from "rimble-ui";
import { truncateAddress } from "../../utils";

const Address = ({ address }) => {
  const [, copyToClipboard] = useCopyToClipboard();

  return (
    <Tooltip placement="top" message={"Click to copy"}>
      <Button.Base
        bg="transparent"
        px={0}
        fontWeight={"400"}
        py={1}
        color="inherit"
        height="unset"
        minHeight="unset"
        onClick={() => copyToClipboard(address)}
        title={address}
      >
        {truncateAddress(address)}
      </Button.Base>
    </Tooltip>
  );
};

export default Address;
