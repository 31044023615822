import React from "react";
import { Box, Button, Card, Flex, Heading, Modal, Table } from "rimble-ui";

export default class AdminEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      select: ""
    };
  }

  onChangeValue(key, v) {
    this.setState({
      [key]: v.target.value
    });
  }

  async addAdmin(select) {
    const {
      provider,
      data: { address }
    } = this.props;
    if (provider && address) {
      if (select === "memberManager") {
        await provider.addMemberAdmin(address);
      } else {
        await provider.addAdmin(select, address);
      }
    }
  }

  async removeAdmin(select) {
    const {
      provider,
      data: { address }
    } = this.props;
    if (provider && address) {
      if (select === "memberManager") {
        await provider.removeMemberAdmin(address);
      } else {
        await provider.removeAdmin(select, address);
      }
    }
  }

  checkIsSuperAdmin(select) {
    const {
      data: { superAdmins }
    } = this.props;
    if (superAdmins && superAdmins.length > 0) {
      for (const v of superAdmins) {
        if (v.value === select) {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  }

  checkIsAdmin(select) {
    const {
      data: { admins }
    } = this.props;
    if (admins && admins.length > 0) {
      for (const v of admins) {
        if (v.value === select) {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  }

  addressIsAdmin(select) {
    const {
      data: { userAdmins }
    } = this.props;
    if (userAdmins && userAdmins.length > 0) {
      for (const v of userAdmins) {
        if (v.value === select) {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  }

  render() {
    const {
      isOpen,
      closeModal,
      options,
      data: { address }
    } = this.props;
    const checkIsSuperAdmin = this.checkIsSuperAdmin.bind(this);
    const checkIsAdmin = this.checkIsAdmin.bind(this);
    const that = this;

    const html = options.map((v, i) => {
      const isAdmin = that.addressIsAdmin(v.value);

      return (
        <tr key={i}>
          <td>{v.label}</td>
          <td>
            {isAdmin ? (
              <Button.Outline
                size="small"
                onClick={that.removeAdmin.bind(that, v.value)}
                disabled={!checkIsSuperAdmin(v.value)}
              >
                Remove
              </Button.Outline>
            ) : (
              <Button
                size="small"
                onClick={that.addAdmin.bind(that, v.value)}
                disabled={!checkIsAdmin(v.value)}
              >
                Add
              </Button>
            )}
          </td>
        </tr>
      );
    });

    return (
      <React.Fragment>
        <Modal isOpen={isOpen}>
          <Card width={"660px"} p={0}>
            <Button.Text
              icononly
              icon={"Close"}
              color={"moon-gray"}
              position={"absolute"}
              top={0}
              right={0}
              mt={0}
              mr={0}
              onClick={closeModal}
            />
            <Box py={40} px={32}>
              <Flex>
                <Box p={3} width={1 / 1}>
                  <Heading.h3>{address ? address : ""}</Heading.h3>
                </Box>
              </Flex>
              <Table bg="white" borderColor="#eee" border="none">
                <thead>
                  <tr>
                    <th>Contract</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{html}</tbody>
              </Table>
            </Box>
          </Card>
        </Modal>
      </React.Fragment>
    );
  }
}
