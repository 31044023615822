import React from "react";
import { Box, Button, Card, Flex, Heading, Input, Modal } from "rimble-ui";

export default class CreditVehicleEdit extends React.Component {
  onChangeValue(key, v) {
    this.setState({
      [key]: v.target.value
    });
  }

  async update() {
    const { provider } = this.props;
    if (provider) {
      await provider.updateCreditVehicleParams(
        Object.assign({}, this.props.data, this.state)
      );
    }
  }

  render() {
    const { isOpen, closeModal, data } = this.props;

    return (
      <React.Fragment>
        <Modal isOpen={isOpen}>
          <Card width={"660px"} p={0}>
            <Button.Text
              icononly
              icon={"Close"}
              color={"moon-gray"}
              position={"absolute"}
              top={0}
              right={0}
              mt={0}
              mr={0}
              onClick={closeModal}
            />
            <Box py={40} px={32}>
              <Flex>
                <Box p={3} width={1 / 2}>
                  <Heading.h3>Interest rate (APR)</Heading.h3>
                  <Input
                    type="text"
                    size="small"
                    placeholder="(%)"
                    width="100%"
                    defaultValue={data.rate}
                    onChange={this.onChangeValue.bind(this, "rate")}
                  />
                </Box>
                <Box p={3} width={1 / 2}>
                  <Heading.h3>Origination fee</Heading.h3>
                  <Input
                    type="text"
                    size="small"
                    placeholder="(%)"
                    width="100%"
                    defaultValue={data.fee}
                    onChange={this.onChangeValue.bind(this, "fee")}
                  />
                </Box>
              </Flex>
              <Flex>
                <Box p={3} width={1 / 2}>
                  <Heading.h3>Loan size max</Heading.h3>
                  <Input
                    type="text"
                    size="small"
                    placeholder=""
                    width="100%"
                    defaultValue={data.loanSizeMax}
                    onChange={this.onChangeValue.bind(this, "loanSizeMax")}
                  />
                </Box>
                <Box p={3} width={1 / 2}>
                  <Heading.h3>Loan size min</Heading.h3>
                  <Input
                    type="text"
                    size="small"
                    placeholder=""
                    width="100%"
                    defaultValue={data.loanSizeMin}
                    onChange={this.onChangeValue.bind(this, "loanSizeMin")}
                  />
                </Box>
              </Flex>
              <Flex>
                <Box p={3} width={1 / 2}>
                  <Heading.h3>Max late duration (epochs)</Heading.h3>
                  <Input
                    type="number"
                    size="small"
                    placeholder=""
                    width="100%"
                    defaultValue={data.maxLateDuration}
                    onChange={this.onChangeValue.bind(this, "maxLateDuration")}
                  />
                </Box>
                <Box p={3} width={1 / 2}>
                  <Heading.h3>Epoch duration (blocks)</Heading.h3>
                  <Input
                    type="number"
                    size="small"
                    placeholder=""
                    width="100%"
                    defaultValue={data.epochDuration}
                    onChange={this.onChangeValue.bind(this, "epochDuration")}
                  />
                </Box>
              </Flex>
            </Box>
            <Flex
              px={4}
              py={3}
              borderTop={1}
              borderColor={"#E8E8E8"}
              justifyContent={"flex-end"}
            >
              <Button.Outline onClick={closeModal}>Cancel</Button.Outline>
              <Button ml={3} onClick={this.update.bind(this)}>
                Update
              </Button>
            </Flex>
          </Card>
        </Modal>
      </React.Fragment>
    );
  }
}
