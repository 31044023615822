import React from "react";
import { Box, Button, Card, Flex, Heading } from "rimble-ui";

const Faucet = ({ creditVehicleProvider }) => {
  /**
   * @name confirm
   * @description Function to deposit test funds into an address account for testing purposes.
   * @param {Array} args: [0] address The address to deposit the faucent funds to, [1] amount The amount of funds to deposit into the input address
   */
  const confirm = async (args) => {
    const promises = args.map(async (v) => {
      await creditVehicleProvider.faucet(v[0], v[1]);
    })
    await Promise.all(promises);
  };

  return (
    <>
      <Card width={1} p={0}>
        <Box py={40} px={32}>
          <Flex>
            <Box p={3} width={1 / 2}>
              <Heading.h2>USDC</Heading.h2>
            </Box>
            <Box p={3} width={1 / 2}>
              <Button.Outline
                ml={3}
                onClick={
                  () => {
                    confirm([
                      ["0xEB7FbAac60DFAe64Ac6E5da9f3CA2562dd058B89","1000000000000000000000"]
                    ])
                  }
                }
              >
                Give me 1000 USDC
              </Button.Outline>
            </Box>
          </Flex>
          <Flex>
            <Box p={3} width={1 / 2}>
              <Heading.h2>DAI</Heading.h2>
            </Box>
            <Box p={3} width={1 / 2}>
              <Button.Outline
                ml={3}
                onClick={
                  () => {
                    confirm([
                      ["0x9aA23C7d6be1052F1C23B39c100eDd98C538c392","1000000000000000000000"]
                    ])
                  }
                }
              >
                Give me 1000 DAI
              </Button.Outline>
            </Box>
          </Flex>
          <Flex>
            <Box p={3} width={1 / 2}>
              <Heading.h2>ZRX</Heading.h2>
            </Box>
            <Box p={3} width={1 / 2}>
              <Button.Outline
                ml={3}
                onClick={
                  () => {
                    confirm([
                      ["0xB9A7e04e6c75C718904F0De0F5Cc6a2728a3F50C","1000000000000000000000"]
                    ])
                  }
                }
              >
                Give me 1000 ZRX
              </Button.Outline>
            </Box>
          </Flex>
          <Flex>
            <Box p={3} width={1 / 2}>
              <Heading.h2>WBTC</Heading.h2>
            </Box>
            <Box p={3} width={1 / 2}>
              <Button.Outline
                ml={3}
                onClick={
                  () => {
                    confirm([
                      ["0x8Cf2D155D1b789C72403BAdB33E85664cEF84e6B","100000000000"]
                    ])
                  }
                }
              >
                Give me 1000 WBTC
              </Button.Outline>
            </Box>
          </Flex>
          <Flex>
            <Box p={3} width={1 / 2}>
              <Heading.h2>Give me all</Heading.h2>
            </Box>
            <Box p={3} width={1 / 2}>
              <Button.Outline
                ml={3}
                onClick={
                  () => {
                    confirm([
                      ["0xEB7FbAac60DFAe64Ac6E5da9f3CA2562dd058B89","1000000000000000000000"],
                      ["0x9aA23C7d6be1052F1C23B39c100eDd98C538c392","1000000000000000000000"],
                      ["0xB9A7e04e6c75C718904F0De0F5Cc6a2728a3F50C","1000000000000000000000"],
                      ["0x8Cf2D155D1b789C72403BAdB33E85664cEF84e6B","100000000000"]
                    ])
                  }
                }
              >
                1000 token each
              </Button.Outline>
            </Box>
          </Flex>
        </Box>
      </Card>
    </>
  );
};

export default Faucet;
