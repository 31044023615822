import React from "react";
import { Flex, Image, Text } from "rimble-ui";

const Token = ({ token, large = false }) => (
  <Flex alignItems="center">
    <Image
      alt={token}
      maxWidth={large ? 40 : 24}
      src={`/tokens/${token}.svg`}
    />
    <Text.p
      fontWeight={600}
      letterSpacing={0.1}
      fontSize={large ? 24 : 14}
      lineHeight={large ? "24px" : "14px"}
      pl={large ? 12 : 8}
    >
      {token}
    </Text.p>
  </Flex>
);

export default Token;
