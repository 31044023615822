import React, { Component } from "react";
import { Card, Flex, Heading, Loader, Table, Text } from "rimble-ui";
import Token from "../Token";
import Address from "../Address";

export default class Loans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loans: [],
      loading: false
    };
  }

  async componentDidMount() {
    this.setState({
      loading: true
    });
    const server = this.props.creditVehicleProvider;
    if (server && server.active) {
      const creditVehicles = this.props.creditVehicles;
      let loans = await server.getLoans(creditVehicles);
      if (loans) {
        loans.sort(function(a, b) {
          return a.token && b.token
            ? a.token.toString().localeCompare(b.token)
            : 1;
        });
        console.log("loans: ", loans);
      } else {
        loans = [];
      }

      this.setState({
        loans,
        loading: false
      });
    }
  }

  render() {
    const that = this;
    const loansHtml = that.state.loans.map(function(v, i) {
      return (
        <tr key={i}>
          <td>
            <Address address={v.member} />
          </td>
          <td>{v.amount.toFixed(4)}</td>
          <td>
            <Token token={v.token} />
          </td>
          <td>{(v.apr * 100).toFixed(2)} %</td>
          <td>{v.interest.toFixed(4)}</td>
          <td>{v.date}</td>
          <td>{v.isOverdue ? "Is Overdue" : "Current"}</td>
        </tr>
      );
    });

    return (
      <>
        <Card p={0} mb={40}>
          <Flex py={24} px={32} justifyContent="space-between">
            <Heading.h2>Loans</Heading.h2>
          </Flex>

          <Table bg="white" borderColor="#eee" border="none">
            <thead>
              <tr>
                <th>Borrowers</th>
                <th>Loan Amount</th>
                <th>Asset</th>
                <th>Interest</th>
                <th>Interest Accrued</th>
                <th>Updated</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {this.state.loading ? (
                <tr>
                  <td colSpan="6">
                    <Text textAlign="center">
                      <Loader size="40px" style={{ display: "inline-block" }} />
                    </Text>
                  </td>
                </tr>
              ) : (
                loansHtml
              )}
            </tbody>
          </Table>
        </Card>
      </>
    );
  }
}
