import React from "react";
import { Box, Button, Card, Field, Flex, Input, Link, Text } from "rimble-ui";

const CreditVehicleInfoCard = ({
  balance,
  amount,
  onChangeValue,
  borrow,
  repay,
  max,
  min,
  borrowed,
  interest
}) => {
  return (
    <Card>
      <Flex>
        <Box px={2} flex={1} color="black" bg="white">
          <Text fontWeight={"bold"}>Wallet Balance: </Text>
          <Text>{balance}</Text>
        </Box>
        <Box px={2} flex={1} color="black" bg="white">
          <Text fontWeight={"bold"}>Balance Owed: </Text>
          <Link
            onClick={max}
            style={{ textDecoration: "underline", color: "#7165d7" }}
          >
            {borrowed}
          </Link>
        </Box>
        <Box px={2} flex={1} color="black" bg="white">
          <Text fontWeight={"bold"}>Min Payment: </Text>
          <Link
            onClick={min}
            style={{ textDecoration: "underline", color: "#7165d7" }}
          >
            {interest}
          </Link>
        </Box>
      </Flex>
      <Flex mt={16}>
        <Input
          type="number"
          required={true}
          min="0"
          width="100%"
          placeholder="Amount"
          onChange={onChangeValue}
          value={amount}
        />
      </Flex>
      <Flex mx={-2} mt={16}>
        <Box px={2} flex={1} color="black" bg="white">
          <Button width="100%" onClick={borrow}>
            Borrow
          </Button>
        </Box>
        <Box px={2} flex={1} color="black" bg="white">
          <Button.Outline width="100%" onClick={repay}>
            Repay
          </Button.Outline>
        </Box>
      </Flex>
    </Card>
  );
};

export default CreditVehicleInfoCard;
