import React, { Component } from "react";
import { Button, Card, Flex, Heading, Loader, Table, Text } from "rimble-ui";
import MemberAddModal from "../Modals/memberAdd.js";
import MemberEditModal from "../Modals/memberEdit.js";
import MemberRemoveModal from "../Modals/memberRemove.js";
import Address from "../Address/index.js";

export default class Members extends Component {
  constructor(props) {
    super(props);
    this.state = {
      members: [],
      menus: [],
      addModalIsOpen: false,
      editModalIsOpen: false,
      removeModalIsOpen: false,
      selectedMember: {},
      isAdmin: false,
      loading: false,
      modalLoading: false
    };
  }

  async componentDidMount() {
    const { creditVehicleProvider: server, creditVehicles } = this.props;
    if (server && server.active) {
      this.setState({
        loading: true
      });
      let memberList = [],
        menus = [];
      const members = await server.getMembers();
      const isAdmin = await server.isMemberAdmin();
      const memberPromises = members.map(async member => {
        let limits = await server.getMemberAllLimits(creditVehicles, member);

        if (limits) {
          limits.sort(function(a, b) {
            return a.token && b.token
              ? a.token.toString().localeCompare(b.token)
              : 1;
          });
        } else {
          limits = [];
        }

        memberList.push({
          address: member,
          limits
        });
      });
      await Promise.all(memberPromises);

      memberList.sort(function(a, b) {
        return a.address && b.address
          ? a.address.toString().localeCompare(b.address)
          : 1;
      });

      const vehiclePromises = creditVehicles.map(async address => {
        const tokenName = await server.getCreditVehicleToken(address);
        menus.push({
          value: address,
          label: tokenName
        });
      });
      await Promise.all(vehiclePromises);

      this.setState({
        members: memberList,
        menus,
        isAdmin,
        loading: false
      });
    }
  }

  closeModal(e) {
    e.preventDefault();
    this.setState((state, props) => ({
      addModalIsOpen: false
    }));
  }

  openModal(e) {
    e.preventDefault();
    this.setState((state, props) => ({
      addModalIsOpen: true
    }));
  }

  closeRemoveModal(e) {
    e.preventDefault();
    this.setState((state, props) => ({
      removeModalIsOpen: false
    }));
  }

  async openRemoveModal(member, e) {
    e.preventDefault();
    this.setState((state, props) => ({
      removeModalIsOpen: true,
      selectedMember: {
        member: member.address
      }
    }));
  }

  closeEditModal(e) {
    e.preventDefault();
    this.setState((state, props) => ({
      editModalIsOpen: false
    }));
  }

  async openEditModal(member, e) {
    e.preventDefault();
    this.setState({
      modalLoading: true,
      editModalIsOpen: true
    });
    const server = this.props.creditVehicleProvider;
    let admins = [];
    const promises = this.props.creditVehicles.map(async address => {
      const isAdmin = await server.isAdmin(address);
      if (isAdmin) admins.push(address);
    });
    await Promise.all(promises);

    this.setState((state, props) => ({
      modalLoading: false,
      selectedMember: {
        admins,
        member: member.address,
        limits: member.limits
      }
    }));
  }

  render() {
    const that = this;

    const { menus, members, isAdmin } = this.state;

    const menusHtml = menus.map((v, i) => {
      return <th key={i}>{v.label}</th>;
    });

    const membersHtml = members.map(function(member, i) {
      if (member.address !== "0x0000000000000000000000000000000000000000") {
        const html = menus.map((menu, i) => {
          let limit = "N/A";
          member.limits.forEach(v => {
            if (menu.label === v.token) {
              limit = v.limit;
            }
          });

          return <td key={i}>{limit}</td>;
        });

        return (
          <tr key={i}>
            <td>
              <Address address={member.address} />
            </td>
            {html}
            <td>
              <Button
                size="small"
                onClick={that.openEditModal.bind(that, member)}
                style={{ marginRight: "10px" }}
              >
                Edit
              </Button>
              <Button.Outline
                size="small"
                onClick={that.openRemoveModal.bind(that, member)}
                disabled={!isAdmin}
              >
                Remove
              </Button.Outline>
            </td>
          </tr>
        );
      }

      return null;
    });

    return (
      <>
        <MemberAddModal
          isOpen={this.state.addModalIsOpen}
          closeModal={this.closeModal.bind(this)}
          provider={this.props.creditVehicleProvider}
        />
        <MemberRemoveModal
          isOpen={this.state.removeModalIsOpen}
          closeModal={this.closeRemoveModal.bind(this)}
          provider={this.props.creditVehicleProvider}
          data={this.state.selectedMember}
        />
        <MemberEditModal
          isOpen={this.state.editModalIsOpen}
          closeModal={this.closeEditModal.bind(this)}
          provider={this.props.creditVehicleProvider}
          loading={this.state.modalLoading}
          data={this.state.selectedMember}
        />

        <Card p={0} mb={40}>
          <Flex py={24} px={32} justifyContent="space-between">
            <Heading.h2>Members</Heading.h2>
            <Button
              size="small"
              style={{ float: "right" }}
              disabled={!this.state.isAdmin}
              onClick={this.openModal.bind(this)}
            >
              Add
            </Button>
          </Flex>
          <Table bg="white" borderColor="#eee" border="none">
            <thead>
              <tr>
                <th>Address</th>
                {menusHtml}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.state.loading ? (
                <tr>
                  <td colSpan="5">
                    <Text textAlign="center">
                      <Loader size="40px" style={{ display: "inline-block" }} />
                    </Text>
                  </td>
                </tr>
              ) : (
                membersHtml
              )}
            </tbody>
          </Table>
        </Card>
      </>
    );
  }
}
